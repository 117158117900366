import { Page } from "@layout/Page";
import { Button, Card, Center, Stack, Text } from "@mantine/core";
import { SQLGenerationTimeout } from "@mm/shared/schemas/text2sql/errors";
import { useText2SqlFeedback } from "../FeedbackModal/hooks";
import type { GoldViewDef } from "../utils";

export const ShowSqlGenerationSqlTimeoutError = ({
  goldView,
}: {
  goldView: GoldViewDef;
}) => {
  const { mutate: retry } = useText2SqlFeedback(goldView);

  if (goldView.status != `ERROR.${SQLGenerationTimeout.NAME}`) {
    console.error(
      `We should not try to render this component if the error is not ERROR.${SQLGenerationTimeout.NAME}`,
    );

    return undefined;
  }

  return (
    <Page title="Insight Results">
      <Card flex={1} radius="lg">
        <Center flex={1}>
          <Stack>
            <Text>
              We apologize for the inconvenience, but our systems took too much
              time to to produce a solution for you query.
            </Text>
            <Center>
              <Button
                onClick={() =>
                  retry({
                    general:
                      "The first generation timed-out, focus on what is important and try to simplify the query if possible",
                    columns: {},
                  })
                }
                variant="default"
              >
                Try again
              </Button>
            </Center>
          </Stack>
        </Center>
      </Card>
    </Page>
  );
};
