import { Group, Table as MantineTable, UnstyledButton } from "@mantine/core";
import { flexRender, type Table } from "@tanstack/react-table";
import { LuChevronDown, LuChevronsUpDown, LuChevronUp } from "react-icons/lu";

type Props<T> = {
  table: Table<Record<PropertyKey, T>>;
};

export const TableHeader = <T,>({ table }: Props<T>) => {
  return (
    <MantineTable.Thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <MantineTable.Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const isSortable = header.column.getCanSort();
            const sortDirection = header.column.getIsSorted();

            // Get the appropriate sort icon
            const SortIcon = (() => {
              if (sortDirection == "asc") return LuChevronUp;
              else if (sortDirection == "desc") return LuChevronDown;
              return LuChevronsUpDown;
            })();

            const value = header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext());

            return (
              <MantineTable.Th key={header.id} w="15rem">
                <UnstyledButton
                  variant="ghost"
                  size="sm"
                  className={`h-8 flex items-center gap-2 font-medium ${
                    isSortable ? "cursor-pointer select-none" : ""
                  }`}
                  onClick={header.column.getToggleSortingHandler()}
                  disabled={!isSortable}
                >
                  <Group w="15rem">
                    {value}
                    {isSortable && (
                      <SortIcon className="h-4 w-4" aria-hidden="true" />
                    )}
                  </Group>
                </UnstyledButton>
              </MantineTable.Th>
            );
          })}
        </MantineTable.Tr>
      ))}
    </MantineTable.Thead>
  );
};
