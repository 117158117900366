import { type FormEvent, useEffect, useRef, useState } from "react";

import { rem } from "@mantine/core";

import { BiUpArrowAlt } from "react-icons/bi";

import { InputWithButton } from "@components/InputWithButton";
import type { FrontendMessage } from "./Chat";

export const Form = ({
  sendMessages,
  loading,
}: {
  sendMessages: (messages: FrontendMessage[]) => void;
  loading: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<number>(-1);

  useEffect(() => {
    // set focus when loading changes
    // specifically when loading comes back from True to False as it "disables" the input and thus loses focus
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [loading]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (loading) return;

    if (inputValue.trim()) {
      sendMessages([{ type: "text", text: inputValue }]);
    }
    setInputValue("");
  };

  const handleChange = (val: string) => {
    window.clearTimeout(timeoutRef.current);
    setInputValue(val);
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputWithButton
        innerRef={inputRef}
        radius="sm"
        size="md"
        placeholder="Reply to MageMetrics"
        // We only disable if "loading" because it means we're waiting on the LLM to reply
        disabled={loading}
        // But we set loading in both cases
        loading={loading}
        rightSectionWidth={40}
        buttonSize={28}
        buttonIcon={
          <BiUpArrowAlt style={{ width: rem(18), height: rem(18) }} />
        }
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        styles={{
          wrapper: {
            "--input-left-section-size": "unset",
            "--input-padding-inline-start": "var(--input-padding)",
          },
          input: {
            paddingInlineStart: "var(--input-padding-inline-start)",
          },
        }}
      />
    </form>
  );
};
