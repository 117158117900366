import { Table } from "@components/Table";
import { Page } from "@layout/Page";
import React from "react";

import { ActionIcon, ActionIconGroup, Box, Text, Tooltip } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { BiChat, BiTrash } from "react-icons/bi";
import { NavLink } from "react-router-dom";

import { useThreadsList } from "../api/useThreadList";

type TableItem = Exclude<
  ReturnType<typeof useThreadsList>["data"],
  undefined | null
>[number];

export const OpenProjects: React.FC = () => {
  const { data, isPending } = useThreadsList();

  const columnHelper = createColumnHelper<TableItem>();

  const columns = [
    columnHelper.display({
      id: "open",
      cell: (props) => (
        <Tooltip label="Open thread">
          <ActionIcon
            variant="light"
            component={NavLink}
            to={`/thread/${props.row.original.id}`}
            onClick={() => console.log(props.row)}
          >
            <BiChat />
          </ActionIcon>
        </Tooltip>
      ),
    }),
    columnHelper.accessor("name", {
      cell: (info) => (
        <Box display={"flex"}>
          <Tooltip multiline maw={"25rem"} label={info.getValue()}>
            <Text truncate="end">{info.getValue()}</Text>
          </Tooltip>
        </Box>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Name
        </Text>
      ),
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => (
        <Box display={"flex"}>
          <Text truncate="end">
            {dayjs(info.getValue()).utc().format("DD MMM. YYYY [at] HH:mm")}
          </Text>
        </Box>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Created at
        </Text>
      ),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <ActionIconGroup>
          <Tooltip label="Remove from catalog">
            <ActionIcon
              variant="subtle"
              color="red"
              onClick={() => console.log(props.row)}
            >
              <BiTrash />
            </ActionIcon>
          </Tooltip>
        </ActionIconGroup>
      ),
    }),
  ];

  return (
    <Page title="Open projects">
      <Table data={data ?? undefined} isPending={isPending} columns={columns} />
    </Page>
  );
};
