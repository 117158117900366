import { renderDbElements } from "@components/Markdown/DbElement";
import { useClient } from "@hooks/use-client";
import {
  Box,
  Center,
  Drawer,
  List,
  Loader,
  Stack,
  Tabs,
  Text,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import {
  LLMVerifyResultsResponse,
  type LLMVerifyResultsResponseType,
} from "@mm/shared/companion/types";
import { useQuery } from "@tanstack/react-query";
import type { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import type { GoldViewDef } from "../utils";
import { QueryDataFlow } from "./QueryDataFlow";
import QueryDetails from "./QueryDetails";

const SECTION_DESCRIPTIONS = {
  "Comparison To Original Request":
    "Analysis of how the SQL implementation matches, differs from, or enhances the initial column selection and requirements.",
  Assumptions:
    "Critical decisions made during SQL implementation that affect how the report data should be interpreted.",
} as const;

interface VerifyResultsDrawerProps {
  goldView: GoldViewDef;
  opened: boolean;
  close: () => void;
}

const useVerifyResults = (opened: boolean, goldViewId: number) => {
  const { fetchAPIWithToken } = useClient();
  return useQuery({
    queryKey: ["verifyResults", goldViewId],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/reports/${goldViewId}/verify`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      return LLMVerifyResultsResponse.parse(await response.json());
    },
    enabled: opened,
  });
};

const ExplanationSection: React.FC<{
  title: keyof typeof SECTION_DESCRIPTIONS;
  content:
    | string
    | string[]
    | Array<{
        type: string;
        details: string;
        explanation: string;
      }>;
}> = ({ title, content }) => {
  return (
    <Box p={"sm"}>
      <Text fw={"bold"}>{title}</Text>
      <Text mb="sm" c={"dimmed"} size="sm">
        {SECTION_DESCRIPTIONS[title]}
      </Text>
      {Array.isArray(content) ? (
        content.length > 0 ? (
          typeof content[0] === "string" ? (
            <List spacing={"xs"}>
              {(content as string[]).map((item, index) => (
                <List.Item key={index}>
                  <TypographyStylesProvider fz="sm">
                    <ReactMarkdown components={{ code: renderDbElements }}>
                      {item}
                    </ReactMarkdown>
                  </TypographyStylesProvider>
                </List.Item>
              ))}
            </List>
          ) : (
            <Stack gap="md">
              {(
                content as Array<{
                  type: string;
                  details: string;
                  explanation: string;
                }>
              ).map((item, index) => (
                <Stack key={index} gap="xs">
                  <Text size="sm" fw={500} tt="capitalize">
                    {item.type} Assumption:
                  </Text>
                  <Text size="sm">{item.details}</Text>
                  <Text size="sm">{item.explanation}</Text>
                </Stack>
              ))}
            </Stack>
          )
        ) : (
          <Text size="sm">None.</Text>
        )
      ) : (
        <TypographyStylesProvider fz="sm">
          <ReactMarkdown components={{ code: renderDbElements }}>
            {content ? content : "None."}
          </ReactMarkdown>
        </TypographyStylesProvider>
      )}
    </Box>
  );
};

const Layout = ({
  opened,
  children,
  close,
}: {
  opened: boolean;
  close: () => void;
  children: ReactNode;
}) => {
  return (
    <Drawer.Root
      position="right"
      size={"100%"}
      offset={8}
      radius="md"
      opened={opened}
      onClose={close}
    >
      <Drawer.Overlay />
      <Drawer.Content
        display={"flex"}
        style={{ flexDirection: "column", alignItems: "stretch" }}
      >
        <Drawer.Header>
          <Title order={4}>Results verification</Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body
          flex={1}
          display={"flex"}
          style={{ flexDirection: "column" }}
        >
          {children}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

const Loading = () => {
  return (
    <Center flex={1}>
      <Stack align="center">
        <Loader />
        <Text c={"dimmed"} size="sm">
          Drilling down your results&hellip;
        </Text>
      </Stack>
    </Center>
  );
};

const ShowResults = ({ data }: { data: LLMVerifyResultsResponseType }) => {
  return (
    <>
      <Text mb={"lg"}>{data.summary}</Text>
      <Tabs
        flex={1}
        defaultValue="Query Insights"
        variant="outline"
        display={"flex"}
        style={{ flexDirection: "column" }}
      >
        <Tabs.List>
          <Tabs.Tab value="Query Insights">Query Insights</Tabs.Tab>
          <Tabs.Tab value="Query Data Flow">Query Data Flow</Tabs.Tab>
          <Tabs.Tab value="Query Details">Query Details</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel flex={1} p={"sm"} value="Query Insights">
          <Stack>
            <ExplanationSection
              title="Comparison To Original Request"
              content={data.comparisonToOriginalRequest}
            />
            <ExplanationSection
              title="Assumptions"
              content={data.assumptions}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel flex={1} p={"sm"} value="Query Data Flow">
          <QueryDataFlow queryDataFlow={data.queryDataFlow} />
        </Tabs.Panel>
        <Tabs.Panel flex={1} p={"sm"} value="Query Details">
          <QueryDetails queryDetails={data.queryDetails} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export const VerifyResultsDrawer: React.FC<VerifyResultsDrawerProps> = ({
  opened,
  goldView,
  close,
}) => {
  const { data, isPending } = useVerifyResults(opened, goldView.id);

  return (
    <Layout opened={opened} close={close}>
      {isPending || !data ? <Loading /> : <ShowResults data={data} />}
    </Layout>
  );
};
