import {
  Stack,
  rem,
  List,
  Button,
  useMantineTheme,
  Loader,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { PiCheckCircleDuotone, PiCircleDashedDuotone } from "react-icons/pi";
import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";

import { KPI_PROPERTIES } from "@constants/kpiProperties";

import { JSONModal } from "@components/Thread/JSONModal";
import type { PropsWithChildren } from "react";

export const Progress = ({ status }: { status?: ProcessStatus }) => {
  const theme = useMantineTheme();
  const [opened, { close, open }] = useDisclosure(false);

  const DoneItem = ({ children }: PropsWithChildren) => (
    <List.Item
      icon={
        <PiCheckCircleDuotone
          style={{ width: rem(24), height: rem(24) }}
          color={theme.colors.teal[5]}
        />
      }
    >
      {children}
    </List.Item>
  );
  const TodoItem = ({ children }: PropsWithChildren) => (
    <List.Item
      c={"dimmed"}
      icon={
        <PiCircleDashedDuotone
          style={{ width: rem(24), height: rem(24) }}
          color={"dimmed"}
        />
      }
    >
      {children}
    </List.Item>
  );

  return (
    <Stack style={{ flexBasis: rem(250), flexShrink: 0, flexGrow: 0 }}>
      <Title order={4}>Progress</Title>
      <Stack flex={1} justify="space-between">
        <List spacing="xs" size="sm" center>
          {status === undefined ? (
            <Loader />
          ) : (
            Object.entries(KPI_PROPERTIES).map(([name, displayName]) => {
              const accessor = name as keyof ProcessStatus;

              if (status[accessor]) {
                return <DoneItem key={name}>{displayName}</DoneItem>;
              } else {
                return <TodoItem key={name}>{displayName}</TodoItem>;
              }
            })
          )}
        </List>
        <Button variant="subtle" size="xs" radius="xs" onClick={open}>
          View full JSON
        </Button>
        <JSONModal status={status} opened={opened} close={close} />
      </Stack>
    </Stack>
  );
};
