import React from "react";
import {
  Stack,
  Group,
  Text,
  Button,
  Title,
  Center,
  Image,
  Box,
  List,
  ListItem,
  ThemeIcon,
  Loader,
} from "@mantine/core";
import { Navigate, useParams } from "react-router-dom";
import { useClient } from "@hooks/use-client";
import { useQuery } from "@tanstack/react-query";
import FinalStepImage from "@images/final-step.svg";
import { BiRightArrowAlt } from "react-icons/bi";
import { useInitiateFlowRecord } from "./useInitiateFlowRecord";
import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";

const useGoldView = (threadId: number, shouldRefresh: boolean) => {
  const { supabase } = useClient();

  return useQuery({
    queryKey: ["goldView", threadId],
    queryFn: async () => {
      const { count } = await supabase
        .from("gold_views")
        .select("*", { head: true, count: "exact" })
        .eq("thread_id", threadId)
        .throwOnError();
      return { count };
    },
    refetchInterval: shouldRefresh ? 1000 : false,
  });
};

export const Completion: React.FC<{ status: ProcessStatus }> = () => {
  const { threadId } = useParams<{ threadId: string }>();
  const { isPending: isInitiating, mutate: initiateMutate } =
    useInitiateFlowRecord();

  const {
    data: goldViewData,
    isPending: isPendingGoldView,
    isError: isErrorGoldView,
    error: goldViewError,
  } = useGoldView(Number(threadId), isInitiating);

  if (isPendingGoldView) {
    return (
      <Center mt={64}>
        <Loader />
      </Center>
    );
  }

  if (isErrorGoldView) {
    return (
      <Center mt={64}>
        <Text>Error: {goldViewError?.message}</Text>
      </Center>
    );
  }

  const handleGenerateResults = () => {
    initiateMutate({
      threadId: Number(threadId),
    });
  };

  if (goldViewData?.count === 0) {
    return (
      <Center mt={64}>
        <Group gap={64} align="flex-start">
          <Box>
            <Image w={200} src={FinalStepImage} />
          </Box>
          <Stack flex={1}>
            <Title order={2}>Great job defining your insight!</Title>
            <Text>
              Your KPI / report definition is complete and ready for processing.
            </Text>
            <Text c="dimmed">Next steps:</Text>
            <List
              c="dimmed"
              spacing="lg"
              withPadding
              center
              icon={
                <ThemeIcon variant="light" size={22} radius="xl">
                  <BiRightArrowAlt size={16} />
                </ThemeIcon>
              }
            >
              <ListItem>Submit your request.</ListItem>
              <ListItem>Our system will process your definition.</ListItem>
              <ListItem>
                Review your results, and provide feedback if needed.
              </ListItem>
            </List>
            <Text c="dimmed" mt="lg">
              Ready to see your data in action?
            </Text>
            <Box>
              <Button onClick={handleGenerateResults} loading={isInitiating}>
                Generate results
              </Button>
            </Box>
          </Stack>
        </Group>
      </Center>
    );
  }

  // Gold view exists, redirect to results
  return <Navigate to={`/results/${threadId}`} replace={true} />;
};
