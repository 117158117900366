import { type DependencyList, useMemo, useRef, useState } from "react";

export const useShadowScroll = (
  sensitivity: number = 0.99,
  dependencyList: DependencyList = [],
) => {
  const viewportRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

  const hasScroll = useMemo(() => {
    if (viewportRef.current) {
      const { scrollHeight, clientHeight } = viewportRef.current;
      return scrollHeight > clientHeight;
    } else {
      return false;
    }
  }, [viewportRef, scrollPosition, ...dependencyList]);

  const fullyScrolled = useMemo(() => {
    if (viewportRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = viewportRef.current;
      return scrollTop / (scrollHeight - clientHeight) > sensitivity;
    } else {
      return false;
    }
  }, [scrollPosition, viewportRef, sensitivity]);

  return {
    fullyScrolled,
    hasScroll,
    viewportRef,
    scrollPosition,
    onScrollPositionChange,
  };
};
