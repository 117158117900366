import type { FormEvent } from "react";

import { useClient } from "@hooks/use-client";
import { NavLink as RouterNavLink, useNavigate } from "react-router-dom";

import {
  Badge,
  Button,
  Drawer,
  Image,
  Loader,
  Stack,
  Text,
} from "@mantine/core";

import { BiCog, BiCollection, BiHome, BiTable } from "react-icons/bi";

import EmptyDataCatalogImage from "@images/empty-data-catalog.svg";
import EmptyReportsImage from "@images/empty-reports.svg";

import dayjs from "dayjs";
import { useGoldViewsList } from "../../api/useGoldViewsList";
import { useReportsList } from "../../api/useReportsList";
import { MenuItem } from "./MenuItem";

export const Menu = () => {
  const navigate = useNavigate();
  const { data: reports } = useReportsList();
  const { data: goldViews } = useGoldViewsList();
  const { supabase, user } = useClient();

  const signOut = async (e: FormEvent) => {
    e.preventDefault();
    await supabase.auth.signOut({ scope: "local" });
  };

  const renderMenuItems = <T extends Record<PropertyKey, unknown>>(
    items: T[] | null | undefined,
    viewAllLink: string,
    emptyState: {
      image: string;
      text: string;
    },
    renderItem: (item: T) => React.ReactNode,
  ) => {
    if (!items) {
      return <Loader />;
    }

    if (items.length === 0) {
      return (
        <Stack style={{ textAlign: "center" }} gap={8} mt={8} mb={8}>
          <Image
            m={"auto"}
            w={"25%"}
            alt="Empty state"
            src={emptyState.image}
          />
          <Text size={"xs"} c={"dimmed"}>
            {emptyState.text}
          </Text>
        </Stack>
      );
    }

    const truncatedItems = items.slice(0, 5);
    return (
      <>
        {truncatedItems.map(renderItem)}
        {items.length > 5 ? (
          <Button
            component={RouterNavLink}
            to={viewAllLink}
            size="xs"
            color="blue"
            variant="transparent"
            fullWidth
          >
            View all {items.length} items &hellip;
          </Button>
        ) : (
          <Button
            component={RouterNavLink}
            to={viewAllLink}
            size="xs"
            color="blue"
            variant="transparent"
            fullWidth
          >
            View details
          </Button>
        )}
      </>
    );
  };

  return (
    <Drawer.Root
      opened={true}
      onClose={() => navigate({ hash: "" })}
      padding={"lg"}
      shadow={"xl"}
      size={"sm"}
      /* 201 to be above Affix default of 200 */
      zIndex={201}
      styles={{
        content: { display: "flex", flexDirection: "column" },
        body: { flex: 1, display: "flex", flexDirection: "column" },
      }}
    >
      <Drawer.Overlay backgroundOpacity={0.1} blur={4} />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title fz={"lg"} fw={"bold"}>
            MageMetrics
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body>
          <Stack flex={1} gap={0}>
            <MenuItem label={"Home"} icon={BiHome} to={"/"} />
            {/* Must set  to={"/results"} so we can automatically open parent NavLink when children NavLink is the active one */}
            <MenuItem label={"Insights"} icon={BiCollection} to={"/results"}>
              {renderMenuItems(
                goldViews,
                "/data-catalog",
                {
                  image: EmptyDataCatalogImage,
                  text: "You haven't saved any insights yet.",
                },
                ({ thread_id, status, table_display_name, created_at }) => (
                  <MenuItem
                    key={thread_id}
                    to={`/results/${thread_id}`}
                    label={table_display_name}
                    description={`From ${dayjs(created_at).utc().fromNow()}`}
                    rightSection={
                      <Badge
                        variant="light"
                        size="xs"
                        color={status !== "FINAL" ? "gray" : undefined}
                      >
                        {status}
                      </Badge>
                    }
                  />
                ),
              )}
            </MenuItem>
            {/* Must set  to={"/report"} so we can automatically open parent NavLink when children NavLink is the active one */}
            <MenuItem label={"Reports"} icon={BiTable} to={"/report"}>
              {renderMenuItems(
                reports,
                "/reports",
                {
                  image: EmptyReportsImage,
                  text: "You haven't started any reports yet.",
                },
                ({ id, name, created_at }) => (
                  <MenuItem
                    key={id}
                    to={`/report/${id}`}
                    label={name ?? ""}
                    description={`From ${dayjs(created_at).utc().fromNow()}`}
                  />
                ),
              )}
            </MenuItem>
            <MenuItem label={"Settings"} icon={BiCog} to={"/settings"} />
          </Stack>
          <Stack gap={"xs"} ta={"center"} mt={12}>
            <Text size={"xs"} c={"dimmed"}>
              Hey, {user?.data?.email}
            </Text>
            <Button
              size={"compact-xs"}
              color="gray"
              variant="transparent"
              onClick={(e) => void signOut(e)}
            >
              Logout
            </Button>
          </Stack>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
