import { ResultsContextProvider } from "@components/Results/ResultsContext";
import type { GoldViewDef } from "@components/Results/utils";
import { useClient } from "@hooks/use-client";
import { Page } from "@layout/Page";
import { Center, Loader, Paper, Skeleton, Stack, Text } from "@mantine/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useThread } from "../api/useThread";
import {
  getGoldViewDetailsQueryKey,
  getLastSuccessGoldViewQueryKey,
  PENDING_DRAFT,
  PENDING_FINAL,
  ResultsContent,
} from "./ResultsContent";

/**
 * Retrieve the last available gold view for this thread
 * This view might not contain data, could be an error, a success or event a
 * placeholder while the background jobs are running.
 * @param threadId the id of the thread to retrieve the gold view for
 * @returns a query to retrieve the gold view
 */
const useGoldViewDetails = (
  threadId: number,
): UseQueryResult<GoldViewDef, Error> => {
  const { fetchAPIWithToken } = useClient();

  return useQuery({
    queryKey: getGoldViewDetailsQueryKey(threadId),
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/threads/${threadId}/gold-views`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch gold view details for thread ${threadId}`,
        );
      }

      const { data } = (await response.json()) as { data: GoldViewDef };

      return data;
    },
    refetchInterval: (query) => {
      const status = query.state.data?.status;
      return status === PENDING_DRAFT || status === PENDING_FINAL
        ? 1000
        : false;
    },
  });
};

/**
 * Retrieve the last successful available gold view for this thread.
 * This view might be (way) older than the result of the `useGoldViewDetails`
 * hook, but it can be used to still display information to the user in case
 * of errors when running subsequent steps of the flow (feedback, finalization).
 *
 * It might not exist, as the flow might have never finished successfully.
 *
 *
 * The query is dependent on the previous query for a few reasons:
 * 1. if the last gold view goes from pending_x to either final or draft then the
 *  view need to be updated
 * 2. if the last gold view id changes (we submitted feedback or finalized the view)
 *  we also need to refetch the latest data
 * @param threadId the id of the thread
 * @param goldView the last gold view (might not be a success)
 * @returns a query to retrieve the last successful gold view for the thread
 */
const useLastSuccessGoldView = (
  threadId: number,
  goldView?: GoldViewDef,
): UseQueryResult<GoldViewDef, Error> => {
  const { fetchAPIWithToken } = useClient();

  return useQuery({
    queryKey: [
      ...getLastSuccessGoldViewQueryKey(threadId),
      { goldViewId: goldView?.id },
      { goldViewStatus: goldView?.status },
    ],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/threads/${threadId}/gold-views?status=success`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch gold view details for thread ${threadId}`,
        );
      }

      const { data } = (await response.json()) as { data: GoldViewDef | null };
      return data;
    },
    enabled:
      goldView?.status !== PENDING_DRAFT && goldView?.status !== PENDING_FINAL,
  });
};

export const Results: React.FC = () => {
  const { threadId: threadIdParam } = useParams();
  const threadId = Number(threadIdParam);

  const { isPending: isGoldViewPending, data: goldView } =
    useGoldViewDetails(threadId);
  const { data: lastSuccessGoldView } = useLastSuccessGoldView(
    threadId,
    goldView,
  );
  const { isPending: isThreadPending, data: thread } = useThread(threadId);

  /**
   * As of now we show a loading screen if:
   * - we are loading a thread
   * - we are loading a gold view
   * - the gold view itself is in a pending state
   *
   *
   * We could definitely improve the handling of the last case, because
   * we could still show some of the past data or the table whenever the new
   * gold view get updated, but it's much easier to deal with the state like
   * this for now.
   */
  if (
    isThreadPending ||
    isGoldViewPending ||
    (goldView &&
      (goldView.status == PENDING_FINAL || goldView.status == PENDING_DRAFT))
  ) {
    if (!goldView && !isGoldViewPending) {
      // what should we do here ?
      console.warn("no gold view were created for this thread");
    }

    const text = (() => {
      if (goldView?.status === PENDING_FINAL) {
        return "We're finalizing your results. This process may take a little while.";
      } else {
        return "We're crunching the numbers and analyzing your data. This may take a few moments.";
      }
    })();

    const kpiName = thread
      ? (thread.process_status?.kpi_name?.key ?? "Preparing your insights")
      : undefined;

    return (
      <Page title="Insight Results">
        <Center miw={"l"} style={{ height: "100%", padding: "2rem" }}>
          <Paper shadow="sm" p="xl" withBorder>
            <Stack align="center">
              {kpiName ? (
                <Text size="xl">{kpiName}</Text>
              ) : (
                <Skeleton height={8} radius="xl" />
              )}
              <Text variant="dimmed">{text}</Text>
              <Loader mt="xl" size="lg" />
            </Stack>
          </Paper>
        </Center>
      </Page>
    );
  }

  /**
   * As of now, if any error occurs, we redirect the user to an error page.
   * We should do better:
   * 1. if we already had data previously we should show them and show the error on the same page
   * 2. if we never got any data, we might want to build a special view to give feedback to the user
   *     and allow them to retry or see what was wrong.
   *
   * 1. is more important than two, because it can be really frustrating not seeing your previous results because something went wrong
   */
  if (!goldView || !thread) {
    return <Navigate to={`/error`} replace={true} />;
  }

  /**
   *
   * We only create and render the context if we have a gold view
   * it makes everything so much easier in the underlying component as we
   * can have the guarantee that we have a gold view available, even if it might
   * be an error or in any state
   */
  return (
    <ResultsContextProvider
      goldView={goldView}
      thread={thread}
      lastSuccessGoldView={lastSuccessGoldView}
    >
      <ResultsContent />
    </ResultsContextProvider>
  );
};
