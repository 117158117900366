import type {
  DetailedReportColumn,
  ReportColumnCandidate,
} from "@mm/shared/schemas/reports";

export type SourceName = string;
export type TableName = string;

export const groupBySourceAndTable = <
  T extends DetailedReportColumn | ReportColumnCandidate,
>(
  cols: T[],
) => {
  // First, group by source name
  const groupedBySource = Object.groupBy(
    cols,
    (col) => col.source_name ?? "null",
  );
  const result: Record<SourceName, Record<TableName, T[]>> = {};

  // Then, for each source group, group by display table name
  for (const [sourceName, sourceCols] of Object.entries(groupedBySource)) {
    if (sourceCols) {
      result[sourceName] = Object.groupBy(
        sourceCols,
        (col) => col.display_table_name ?? "null",
      ) as Record<TableName, T[]>;
    }
  }

  return result;
};
