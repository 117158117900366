import {
  type SupabaseClient,
  type User,
  type Session,
  createClient,
} from "@supabase/supabase-js";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { API_URL } from "../../config";
import type { Database } from "@mm/shared/schemas/supabase/database.types";

const supabase = createClient<Database>(
  import.meta.env.VITE_SUPABASE_API_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
);

interface UserData {
  data?: User;
  session?: Session;
  loading: boolean;
}

export type APIEndpoint = `/api/${string}`;

export type ContextType = {
  supabase: SupabaseClient<Database>;
  fetchAPIWithToken: (
    apiRoute: APIEndpoint,
    init?: RequestInit,
  ) => Promise<Response>;
  user: UserData;
  setUser: (user: UserData) => void;
};

const ClientContext = createContext<ContextType>({
  supabase: supabase,
  fetchAPIWithToken: () => {
    /* do nothing */
    throw new Error();
  },
  user: {
    data: undefined,
    session: undefined,
    loading: true,
  },
  setUser: () => {
    /* do nothing */
  },
});

export const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserData>({
    data: undefined,
    session: undefined,
    loading: true,
  });

  const fetchAPIWithToken = useCallback(
    async (apiRoute: APIEndpoint, options?: RequestInit) => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session?.access_token) {
        const updateOptions = (options?: RequestInit) => {
          const update = { ...options };
          update.headers = {
            ...update.headers,
            Authorization: `Bearer ${session?.access_token}`,
          };
          return update;
        };

        return fetch(API_URL + apiRoute, updateOptions(options));
      } else {
        return fetch(API_URL + apiRoute, options);
      }
    },
    [user.session?.access_token],
  );

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      setUser({
        data: session?.user ?? undefined,
        session: session ?? undefined,
        loading: false,
      });
    };
    fetchSession().catch(console.error);

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_, session) => {
      setUser({
        data: session?.user ?? undefined,
        session: session ?? undefined,
        loading: false,
      });
    });

    return () => subscription.unsubscribe();
  }, []);

  const value = {
    supabase,
    fetchAPIWithToken,
    user,
    setUser,
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = () => useContext(ClientContext);
