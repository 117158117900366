import { Badge, HoverCard, Loader, Text } from "@mantine/core";
import React from "react";

const DbElement: React.FC<{
  elementType: string;
  elementName: string;
  fullPath: string;
}> = ({ elementType, elementName, fullPath }) => {
  const colors: Record<string, string> = {
    source: "red",
    table: "green",
    column: "blue",
  };

  return (
    <HoverCard
      width={450}
      shadow="lg"
      withArrow
      openDelay={200}
      closeDelay={400}
    >
      <HoverCard.Target>
        <Badge
          style={{ cursor: "pointer" }}
          component={"span"}
          color={colors[elementType] || "gray"}
          variant="light"
        >
          {elementType}: {elementName}
        </Badge>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Loader />
        <Text c={"dimmed"} truncate="end">
          Full path: {fullPath}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export const renderDbElements = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<"code">) => {
  if (typeof children === "string") {
    const regex = /(source|table|column):([^\]]+)/;
    const match = children.match(regex);
    if (match) {
      const [, elementType, fullPath] = match;
      const displayName = fullPath?.split(".").pop() || fullPath;
      return (
        <DbElement
          elementType={String(elementType)}
          elementName={String(displayName)}
          fullPath={String(fullPath)}
        />
      );
    }
  }

  return (
    <code className={className} {...props}>
      {children}
    </code>
  );
};
