import { ClarifyConcepts } from "./ClarifyConcepts";
import { Completion } from "./Completion";
import { DefineKPI } from "./DefineKPI";
import { DefineVisualization } from "./DefineVisualization";

export const threadQueryKeys = (threadId: number) => {
  return {
    concepts: ["threads", "extractConcepts", { threadId }],
    visualizations: ["threads", "extractVisualizations", { threadId }],
  } as const;
};

export const STEPS = [
  {
    url: "kpi",
    description: "Specify KPI details",
    component: DefineKPI,
  },
  {
    url: "concepts",
    description: "Refine KPI concepts",
    component: ClarifyConcepts,
  },
  {
    url: "visualization-types",
    description: "Define visualization type",
    component: DefineVisualization,
  },
  {
    url: "completion",
    description: "Insight captured",
    component: Completion,
  },
] as const;
