import { Navigate, useParams } from "react-router-dom";
import { Group } from "@mantine/core";

import { Chat } from "@components/Thread/Chat";
import { Progress } from "@components/Thread/Progress";
import type { Dispatch, SetStateAction } from "react";
import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";

export const DefineKPI = ({
  setStatus,
  nextStep,
  status,
}: {
  setStatus: Dispatch<SetStateAction<ProcessStatus>>;
  status: ProcessStatus;
  nextStep: () => void;
}) => {
  const { threadId } = useParams<{ threadId: string }>();

  if (!threadId) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Group flex={1} mih={0} align="stretch" gap={"xl"}>
      <Chat
        key={threadId}
        threadId={Number(threadId)}
        status={status}
        setStatus={setStatus}
        nextStep={nextStep}
      />
      <Progress status={status} />
    </Group>
  );
};
