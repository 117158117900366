import { ShowQueryHandlerError } from "@components/Results/Errors/ShowQueryHandlerError";
import { ShowSqlGenerationSqlTimeoutError } from "@components/Results/Errors/ShowSqlGenerationTimeoutError";

import { Table } from "@components/Results/Table";
import type { GoldViewDef } from "@components/Results/utils";
import { VerifyResultsDrawer } from "@components/Results/VerifyResultsDrawer";
import { useClient } from "@hooks/use-client";
import { Page } from "@layout/Page";
import {
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  QueryHandlingError,
  SQLGenerationTimeout,
} from "@mm/shared/schemas/text2sql/errors";
import { PENDING_DRAFT, PENDING_FINAL } from "@pages/ResultsContent";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { BiCheckShield, BiEdit, BiExport } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

interface ExportMutationVariables {
  id: number;
}

interface ExportMutationResult {
  success: boolean;
}

export const getGoldViewDetailsQueryKey = (reportId: number) => [
  "goldViewDetails",
  reportId,
];

/**
 * Downloads a blob from a Response object as a file.
 *
 * This is required because it's not possible to trigger a browser
 * download from a POST request.
 *
 * We are creating a DOM node and simulating a click in order to trigger
 * the download of a blob that we previously stored from the request.
 * @param res The response from the webserver
 */
const downloadBlob = async (res: Response) => {
  const contentDisposition = res.headers.get("Content-Disposition");
  let filename = "download.csv";

  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/i);
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1];
    }
  }

  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.download = filename;
  fileLink.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(fileURL);
  }, 100);
};

const useExportView = () => {
  const { fetchAPIWithToken } = useClient();

  return useMutation<ExportMutationResult, Error, ExportMutationVariables>({
    mutationFn: async ({ id }: { id: number }) => {
      const response = await fetchAPIWithToken(`/api/gold/views/${id}/export`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to export data for gold view: ${response.statusText}`,
        );
      }

      await downloadBlob(response);

      return { success: true };
    },
  });
};

const useGoldView = (goldViewId: number) => {
  const { supabase } = useClient();

  return useQuery({
    queryKey: ["goldView", { id: goldViewId }],
    queryFn: async () => {
      const { data: goldView } = await supabase
        .from("gold_views")
        .select()
        .eq("id", goldViewId)
        .single()
        .throwOnError();

      return goldView as GoldViewDef;
    },
    refetchInterval: (query) => {
      const status = query.state.data?.status;
      return status === PENDING_DRAFT || status === PENDING_FINAL
        ? 1000
        : false;
    },
  });
};

export const GoldViewResults = () => {
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const goldViewId = Number(useParams().goldViewId);
  const { isPending: isExporting, mutate: exportMutate } = useExportView();
  const navigate = useNavigate();

  const handleExport = useCallback(() => {
    if (!goldViewId) {
      return;
    }

    exportMutate({ id: goldViewId });
  }, [goldViewId, exportMutate]);

  const { isPending: loadingView, data: goldView } = useGoldView(goldViewId);

  if (loadingView) {
    return (
      <Center flex={1}>
        <Loader />
      </Center>
    );
  }

  /*
   * We need to deal with the case where the first run is failing, i.e:
   * lastSuccessGoldView is null goldView.status is ERROR.XXXXX
   */

  if (!goldView) {
    return <div>redirect to home</div>;
  }

  if (goldView.status == `ERROR.${QueryHandlingError.NAME}`) {
    return <ShowQueryHandlerError goldView={goldView} />;
  } else if (goldView.status == `ERROR.${SQLGenerationTimeout.NAME}`) {
    return <ShowSqlGenerationSqlTimeoutError goldView={goldView} />;
  }

  const isStatusPending =
    goldView.status === PENDING_DRAFT || goldView.status == PENDING_FINAL;

  if (isStatusPending) {
    return <div>sql generation is running, please be patient</div>;
  }

  const isFinal = goldView.status == "FINAL";
  const isPreview = !isFinal;

  const leftSection = (
    <Group gap={"xs"}>
      <Badge size="sm" variant="light" color={isPreview ? "gray" : undefined}>
        {isPreview ? "Draft" : "Final"}
      </Badge>
      <Tooltip
        w={300}
        multiline
        withArrow
        label={
          isPreview ? (
            <Text size="sm">
              <Text span fw={"bold"}>
                DRAFT MODE:
              </Text>{" "}
              Results based on data sample. Focus on structure and relevance of
              columns rather than exact values. You can still improve or
              finalize these results.
            </Text>
          ) : (
            <Text size="sm">
              <Text span fw={"bold"}>
                FINALIZED RESULTS:
              </Text>{" "}
              Results based on full dataset. Ready for use in reports and
              decision-making.
            </Text>
          )
        }
      >
        <Text size="xs" c={"blue"} style={{ cursor: "pointer" }}>
          What does it mean?
        </Text>
      </Tooltip>
    </Group>
  );

  const rightSection = (
    <Group gap={"xs"} ta={"right"}>
      <Button
        size="xs"
        variant="light"
        leftSection={<BiCheckShield size={14} />}
        onClick={openDrawer}
      >
        Results verification
      </Button>
      {isFinal && (
        <Button
          size="xs"
          leftSection={<BiExport size={14} />}
          disabled={isStatusPending || isExporting}
          onClick={handleExport}
          loading={isExporting}
        >
          Export
        </Button>
      )}
      {goldView.report_id ? (
        <Button
          size="xs"
          leftSection={<BiEdit size={14} />}
          onClick={() => navigate(`/report/${goldView.report_id}`)}
          loading={isExporting}
        >
          Go to builder
        </Button>
      ) : null}
    </Group>
  );

  return (
    <Page
      title="Report Results"
      leftSection={leftSection}
      rightSection={rightSection}
    >
      {goldView && <Table goldView={goldView} lastSuccessGoldView={goldView} />}
      <VerifyResultsDrawer
        goldView={goldView}
        opened={openedDrawer}
        close={closeDrawer}
      />
    </Page>
  );
};
