import {
  ActionIcon,
  Button,
  Center,
  Group,
  Input,
  Stack,
  Transition,
} from "@mantine/core";
import { getHotkeyHandler } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useReportBuilder } from "../ReportBuilderContext";
import { Messages } from "./Messages";
type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Chat: React.FC<Props> = ({ opened, setOpened }) => {
  const { postMessage, isPosting } = useReportBuilder();

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (inputRef.current && opened) {
      // TODO: Not sure why focus on its own doesn't work, ugly render loop hack
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 0);
    }
  }, [inputRef?.current, opened]);

  const handleSubmit = async () => {
    if (value) {
      try {
        postMessage(value);
        setValue("");
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <Group flex={0} gap={"xs"}>
      <Transition
        mounted={opened}
        transition="slide-right"
        duration={200}
        timingFunction="ease"
        keepMounted
      >
        {(styles) => (
          <Stack
            pt={20}
            style={styles}
            flex={1}
            w={"30rem"}
            align="stretch"
            h={"100%"}
            justify="space-between"
          >
            <Messages />
            <Input
              ref={inputRef}
              disabled={isPosting}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              size="md"
              rightSectionPointerEvents="all"
              rightSectionWidth={75}
              rightSection={
                <Button
                  disabled={isPosting}
                  variant="subtle"
                  size="xs"
                  onClick={handleSubmit}
                >
                  Reply
                </Button>
              }
              onKeyDown={getHotkeyHandler([
                ["mod+K", () => setOpened(!opened)],
                ["Enter", handleSubmit],
              ])}
            />
          </Stack>
        )}
      </Transition>
      <Center flex={0}>
        <ActionIcon variant="subtle" onClick={() => setOpened(!opened)}>
          {opened ? <BiChevronLeft size={24} /> : <BiChevronRight size={24} />}
        </ActionIcon>
      </Center>
    </Group>
  );
};
