// TODO: Pull this from the prompt files of the backend
export const KPI_PROPERTIES = {
  key_metric: "Key metrics defined",
  timeframe: "Timeframe defined",
  filters: "Filtering elements defined",
  filter_values: "Filtering criteria defined",
  groupings: "Grouping elements defined",
  aggregation_methods: "Aggregation methods defined",
  orderings: "Ordering elements defined",
  kpi_name: "KPI name defined",
};
