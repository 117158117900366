import type { ReactNode } from "react";

import {
  Paper,
  type PaperProps,
  Stack,
  Text,
  TypographyStylesProvider,
  useMantineTheme,
} from "@mantine/core";

import ReactMarkdown from "react-markdown";

const Message = ({
  children,
  ...rest
}: PaperProps & { children: ReactNode }) => {
  return (
    <Paper p="md" radius="sm" {...rest}>
      {(() => {
        switch (typeof children) {
          case "string":
            return (
              <TypographyStylesProvider>
                <ReactMarkdown>{children}</ReactMarkdown>
              </TypographyStylesProvider>
            );

          case "object":
            return children;
          default:
            break;
        }
      })()}
    </Paper>
  );
};

export const AssistantMessage = ({ children }: { children?: ReactNode }) => {
  return (
    <Stack gap={2} style={{ textAlign: "left" }}>
      <Text size="xs" c={"dimmed"}>
        MageMetrics
      </Text>
      <Message>{children}</Message>
    </Stack>
  );
};

export const UserMessage = ({ children }: { children?: ReactNode }) => {
  const theme = useMantineTheme();

  return (
    <Stack gap={2} style={{ textAlign: "right" }}>
      <Text size="xs" c={"dimmed"}>
        You
      </Text>
      <Message bg={theme.colors?.gray?.[1]}>{children}</Message>
    </Stack>
  );
};
