import { useClient } from "@hooks/use-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { GoldViewDef } from "../utils";
import type { Feedback } from "@mm/shared/companion/types";

export const useText2SqlFeedback = (goldView: GoldViewDef) => {
  const client = useQueryClient();
  const { fetchAPIWithToken } = useClient();

  return useMutation({
    mutationFn: async (feedback: Feedback) => {
      const response = fetchAPIWithToken(
        `/api/flow/${goldView.thread_id}/feedback`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(feedback),
        },
      );

      await Promise.all([
        response,
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          client
            .refetchQueries({
              queryKey: ["goldViewDetails", goldView.thread_id],
              type: "all",
            })
            .catch(() => {});
        }),
      ]);

      return { success: true };
    },
  });
};
