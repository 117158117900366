export type VisualizationType = {
  selected: boolean;
  explanation?: string;
  type: string;
};

export const VISUALIZATION_TYPES = [
  // Comparisons
  { type: "BAR_CHART", category: "Comparisons" },
  { type: "STACKED_BAR_CHART", category: "Comparisons" },
  { type: "GROUPED_BAR_CHART", category: "Comparisons" },
  { type: "LOLLIPOP_CHART", category: "Comparisons" },

  // Compositions
  { type: "PIE_CHART", category: "Compositions" },
  { type: "TREEMAP", category: "Compositions" },
  { type: "DONUT_CHART", category: "Compositions" },

  // Distributions
  { type: "HISTOGRAM", category: "Distributions" },

  // Trends over time
  { type: "LINE_CHART", category: "Trends over time" },
  { type: "TIMELINE", category: "Trends over time" },

  // Relationships
  { type: "SCATTER_CHART", category: "Relationships" },
  { type: "HEATMAP", category: "Relationships" },

  // Part-to-whole
  { type: "WATERFALL_CHART", category: "Part-to-whole" },
  { type: "FUNNEL_CHART", category: "Part-to-whole" },
  { type: "WAFFLE_CHART", category: "Part-to-whole" },

  // Spatial
  { type: "CHOROPLETH_MAP", category: "Spatial" },

  // Multi-dimensional
  { type: "RADAR_CHART", category: "Multi-dimensional" },
  { type: "PARALLEL_COORDINATES_CHART", category: "Multi-dimensional" },

  // Text and Categorical
  { type: "WORD_CLOUD", category: "Text and Categorical" },
] as const;
