import { useClient } from "@hooks/use-client";
import { useShadowScroll } from "@hooks/use-shadow-scroll";
import {
  Button,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { Scrollbar } from "@components/Scrollbar";
import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";
import type { VisualizationType } from "@mm/shared/companion/visualizationTypes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { threadQueryKeys } from "..";
import classes from "./DefineVisualization.module.css";
import { VisualizationItem } from "./VisualizationItem";

const useVisualization = (
  threadId: number,
  options?: Partial<{
    staleTime: number;
  }>,
) => {
  const { fetchAPIWithToken } = useClient();
  const query = useQuery({
    queryKey: threadQueryKeys(threadId).visualizations,
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/threads/${threadId}/visualization-types`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );
      const newAssistantMessage = await response.json();

      if (!newAssistantMessage) {
        throw Error("No visualization types detected");
      }

      return newAssistantMessage.visualizationTypes as unknown as VisualizationType[];
    },
    staleTime: options?.staleTime,
  });

  const [visualizationTypes, setVisualizationTypes] = useState<
    VisualizationType[]
  >(query.data || []);

  useEffect(() => {
    setVisualizationTypes(query.data || []);
  }, [query.data]);

  return {
    ...query,
    visualizationTypes,
    setVisualizationTypes,
  };
};

export const DefineVisualization = ({
  status,
  nextStep,
  previousStep,
}: {
  status: ProcessStatus;
  previousStep: () => void;
  nextStep: () => void;
}) => {
  const { threadId } = useParams();
  const { visualizationTypes, isLoading, setVisualizationTypes } =
    useVisualization(Number(threadId), { staleTime: Infinity });
  const { viewportRef, hasScroll, fullyScrolled, onScrollPositionChange } =
    useShadowScroll(0.95, [visualizationTypes]);

  return (
    <Stack flex={1} mih={0}>
      <Stack gap={0} mb={14}>
        <Text size="lg" mb={6}>
          Determine the best visual representation for this KPI
        </Text>
        <Text c={"dimmed"}>{status?.kpi_name?.key}</Text>
      </Stack>

      <Title order={3}>Visualization types</Title>
      <Scrollbar
        className={`${hasScroll ? classes.scrollarea : ""} ${
          fullyScrolled ? classes.scrolledToBottom : ""
        }`}
        viewportRef={viewportRef}
        flex={1}
        offsetScrollbars
        type="auto"
        scrollbarSize={8}
        onScrollPositionChange={onScrollPositionChange}
      >
        <SimpleGrid cols={3} pr={8} p={"lg"} spacing={"lg"}>
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ backgroundOpacity: 0, blur: 0 }}
          />
          {visualizationTypes.map((item) => (
            <VisualizationItem
              key={item.type}
              {...item}
              setVisualizationTypes={setVisualizationTypes}
            />
          ))}
        </SimpleGrid>
      </Scrollbar>
      <Group mt={"lg"} justify="space-between">
        <Button tabIndex={3} variant={"light"} onClick={previousStep}>
          Back
        </Button>
        {!isLoading && (
          <Button tabIndex={2} onClick={nextStep}>
            Next
          </Button>
        )}
      </Group>
    </Stack>
  );
};
