import { useClient } from "@hooks/use-client";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Group,
  Highlight,
  LoadingOverlay,
  Popover,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { ConceptItem } from "./ConceptItem";

import { useShadowScroll } from "@hooks/use-shadow-scroll";
import classes from "./ConceptsList.module.css";

import { Scrollbar } from "@components/Scrollbar";
import type { Concept } from "@mm/shared/companion/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { threadQueryKeys } from "..";
import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";

const useConcepts = (threadId: number) => {
  const { fetchAPIWithToken } = useClient();

  const query = useQuery({
    queryKey: ["threads", "extractConcepts", { threadId }],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/threads/${threadId}/concepts`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );
      const newAssistantMessage = (await response.json()) as
        | { concepts: Concept[] }
        | undefined;

      if (!newAssistantMessage) {
        throw Error("No concepts detected");
      }

      return newAssistantMessage.concepts;
    },
    staleTime: Infinity,
  });

  const dbConcepts = useMemo(() => query.data || [], [query.data]);
  const [concepts, setConcepts] = useState<Concept[]>([]);

  // we want the user to be able to edit the concepts, so we need to keep the
  // concepts in sync with the query
  useEffect(() => {
    setConcepts(dbConcepts);
  }, [dbConcepts]);

  /**
   * dbConcepts represent the original list of concepts as loaded from the database, it does
   * not contains the edits from the user
   *
   * Because we are using a staleTime of Infinity we will never overwrite the changes
   * that are being made by the user
   */
  return {
    ...query,
    dbConcepts,
    concepts,
    setConcepts,
  };
};

export const ClarifyConcepts = ({
  nextStep,
  previousStep,
  status,
}: {
  status: ProcessStatus;
  previousStep: () => void;
  nextStep: () => void;
}) => {
  const { supabase } = useClient();
  const queryClient = useQueryClient();
  const [confirmationOpened, setConfirmationOpened] = useState(false);
  const { threadId } = useParams<{ threadId: string }>();
  const { dbConcepts, concepts, setConcepts, isLoading } = useConcepts(
    Number(threadId),
  );

  const { viewportRef, hasScroll, fullyScrolled, onScrollPositionChange } =
    useShadowScroll(0.95, [concepts]);

  const updateConcept = (index: number, updatedConcept: Concept) => {
    setConcepts((prevConcepts) => {
      const newConcepts = [...prevConcepts];
      newConcepts[index] = updatedConcept;
      return newConcepts;
    });
  };

  const saveConcepts = async () => {
    // only store the concepts that were changed
    const changedConcepts = concepts.filter((concept) => {
      const originalConcept = dbConcepts.find(
        (dbConcept) => dbConcept.id === concept.id,
      );
      return (
        originalConcept && originalConcept.definition !== concept.definition
      );
    });

    // bulk upsert does not work here, it is compaining about the id column
    // "upsert cannot insert a non-default"
    await Promise.allSettled(
      changedConcepts.map((concept) => {
        return supabase
          .from("thread_concepts")
          .update({
            definition: concept.definition,
          })
          .eq("id", concept.id);
      }),
    );

    if (changedConcepts.length > 0) {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: threadQueryKeys(Number(threadId)).concepts,
        }),

        queryClient.removeQueries({
          queryKey: threadQueryKeys(Number(threadId)).visualizations,
        }),
      ]);
    }
  };

  const allConceptsDefined = concepts.every(
    (concept) => concept.definition && concept.definition.trim() !== "",
  );

  const kpiName = status?.kpi_name?.key ?? "";

  return (
    <Stack flex={1} mih={0}>
      <Stack gap={0} mb={14}>
        <Text size="lg" mb={6}>
          Define the following concepts to provide additional knowledge
        </Text>

        <Highlight
          highlight={concepts.map(({ concept }) => concept)}
          c="dimmed"
          color="var(--mantine-primary-color-0)"
          highlightStyles={{
            padding: 5,
            borderRadius: 5,
          }}
        >
          {kpiName}
        </Highlight>
      </Stack>

      <Title order={3}>Concepts identified</Title>
      <Scrollbar
        className={`${hasScroll ? classes.scrollarea : ""} ${
          fullyScrolled ? classes.scrolledToBottom : ""
        }`}
        viewportRef={viewportRef}
        flex={1}
        offsetScrollbars
        type="auto"
        scrollbarSize={8}
        onScrollPositionChange={onScrollPositionChange}
      >
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        />

        {!isLoading && (
          <Stack gap={"lg"} pr={8}>
            {concepts.map((concept, index: number) => (
              <ConceptItem
                key={`${concept.concept}-${index}`}
                concept={concept}
                updateConcept={(updatedConcept) =>
                  updateConcept(index, updatedConcept)
                }
              />
            ))}
          </Stack>
        )}
      </Scrollbar>
      <Group mt={"lg"} justify="space-between">
        <Button tabIndex={3} variant={"light"} onClick={previousStep}>
          Back
        </Button>
        {!isLoading && (
          <Popover
            withArrow
            position="top"
            width={350}
            opened={confirmationOpened}
            onChange={setConfirmationOpened}
          >
            <Popover.Target>
              <Button
                tabIndex={2}
                loading={isLoading}
                onClick={async () => {
                  await saveConcepts();

                  if (allConceptsDefined) {
                    nextStep();
                  } else {
                    setConfirmationOpened(true);
                  }
                }}
              >
                Next
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Text>
                Not all concepts have been defined. Are you sure you want to
                proceed?
              </Text>
              <Group mt={18} justify="space-between">
                <Button
                  variant={"light"}
                  onClick={() => setConfirmationOpened(false)}
                >
                  Cancel
                </Button>
                <Button onClick={nextStep}>Proceed anyway</Button>
              </Group>
            </Popover.Dropdown>
          </Popover>
        )}
      </Group>
    </Stack>
  );
};
