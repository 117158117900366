import { Card, Skeleton, Stack, Text } from "@mantine/core";

export const WaitingMessage = () => {
  return (
    <Card radius="md">
      <Stack gap={"xs"}>
        <Text size="xs" c={"dimmed"}>
          MageMetrics is writing&hellip;
        </Text>
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} width={"70%"} radius="xl" />
      </Stack>
    </Card>
  );
};
