import { useNavigate } from "react-router-dom";

import { ActionIcon, Group, Stack, Text, Title, Tooltip } from "@mantine/core";

import { BiArrowToRight } from "react-icons/bi";

interface PageProps {
  fullWidth?: boolean;
  title: string;
  subtitle?: string;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({
  fullWidth = false,
  title,
  subtitle,
  leftSection,
  rightSection,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <Stack w={fullWidth ? "100%" : "90%"} pt={20} h={"100vh"}>
      <Group
        /* Header width must be 90% when the full page is fullWidth, so it remains unchanged no matter if fullWidth or not */
        w={fullWidth ? "90%" : "100%"}
        mx={"auto"}
        justify="space-between"
        align="flex-start"
      >
        <Group gap={"xs"} align="center">
          <Tooltip label="Show menu" offset={8}>
            <ActionIcon
              onClick={() => navigate({ hash: "menu" })}
              variant={"subtle"}
              size={"md"}
            >
              <BiArrowToRight size={20} />
            </ActionIcon>
          </Tooltip>
          <Group align="center">
            <Stack gap={0}>
              {!!subtitle && <Text size="lg">{subtitle}</Text>}
              <Title order={3}>{title}</Title>
            </Stack>
            {leftSection}
          </Group>
        </Group>
        {rightSection}
      </Group>
      {children}
    </Stack>
  );
};
