import { ScrollArea, type ScrollAreaProps } from "@mantine/core";
import classes from "./Scrollbar.module.css";

export const Scrollbar = ({
  children,
  ...props
}: ScrollAreaProps & {
  children: React.ReactNode;
}) => {
  return (
    <ScrollArea {...props} classNames={classes}>
      {children}
    </ScrollArea>
  );
};
