import { useClient } from "@hooks/use-client";
import type { Feedback, Thread } from "@mm/shared/companion/types";
import { useQueries } from "@tanstack/react-query";
import React, { createContext, useContext, useState } from "react";
import { z } from "zod";
import type { GoldViewDef } from "./utils";

const ColumnsResultParser = z.object({
  data: z.object({ name: z.string() }).array(),
});

export const useResultsColumns = (goldView: GoldViewDef | undefined) => {
  const { fetchAPIWithToken } = useClient();
  const goldViewId = goldView?.id;
  return useQueries({
    queries: [
      {
        queryKey: ["newColumns", { goldViewId }],
        queryFn: async () => {
          const response = await fetchAPIWithToken(
            `/api/gold/views/${goldViewId}/columns?intermediate`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            },
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch new columns for gold view ${goldViewId}`,
            );
          }

          const { data } = ColumnsResultParser.parse(await response.json());
          return data.map(({ name }) => name);
        },
        enabled: goldView && goldView.status !== "FINAL",
      },
      {
        queryKey: ["resultsColumns", { goldViewId }],
        queryFn: async () => {
          const response = await fetchAPIWithToken(
            `/api/gold/views/${goldViewId}/columns`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            },
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch results columns for gold view ${goldViewId}`,
            );
          }

          const { data } = ColumnsResultParser.parse(await response.json());
          return data.map(({ name }) => name);
        },
        enabled: !!goldViewId,
      },
    ],
    combine: (results): ResultsColumnsData => {
      const [newColumnsResult, resultsColumnsResult] = results;
      const newColumns = newColumnsResult.data || [];
      const allColumns = resultsColumnsResult.data || [];
      return {
        data: allColumns.map((name) => ({
          name,
          isNew: newColumns.includes(name),
        })),
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        error: results.find((result) => result.error)?.error,
      };
    },
  });
};

interface ResultsColumnsData {
  data: { name: string; isNew: boolean }[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null | undefined;
}

interface ResultsContextType {
  thread: Thread;
  feedback: Feedback;
  setFeedback: React.Dispatch<React.SetStateAction<Feedback>>;
  goldView: GoldViewDef;
  lastSuccessGoldView: GoldViewDef | undefined;
}

const ResultsContext = createContext<ResultsContextType | undefined>(undefined);

interface ResultsProviderProps {
  goldView: GoldViewDef;
  thread: Thread;
  lastSuccessGoldView: GoldViewDef | undefined;
  children: React.ReactNode;
}

export const ResultsContextProvider: React.FC<ResultsProviderProps> = ({
  goldView,
  thread,
  lastSuccessGoldView,
  children,
}) => {
  /*
   * We store the feedback in the context that way it's not tied to the state
   * of the modal.
   * If the modal is closed the state would be persisted (i.e not lost)
   */
  const [feedback, setFeedback] = useState<Feedback>({
    general: "",
    columns: {},
  });

  const value: ResultsContextType = {
    thread,
    feedback,
    setFeedback,
    goldView,
    lastSuccessGoldView,
  };

  return (
    <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>
  );
};

export const useResults = (): ResultsContextType => {
  const context = useContext(ResultsContext);
  if (context === undefined) {
    throw new Error("useResults must be used within a ResultsContextProvider");
  }
  return context;
};
