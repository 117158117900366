import { useClient } from "@hooks/use-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type InitiateFlowRecordParams = {
  threadId: number;
};

export const useInitiateFlowRecord = () => {
  const { fetchAPIWithToken } = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ threadId }: InitiateFlowRecordParams) => {
      const response = await fetchAPIWithToken(`/api/flow/${threadId}/init`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error("Failed to initiate flow record");
      }

      return await response.json();
    },
    onSettled: async (_data, _error, variables) => {
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: ["goldView", variables.threadId],
        }),
      ]);
    },
  });
};
