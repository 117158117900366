import { useHover } from "@mantine/hooks";

import { Badge, Card, Stack, Text } from "@mantine/core";
import classes from "./Recommendation.module.css";

export const Recommendation = ({
  createReport,
  starter,
  explanation,
  type,
  ...props
}: {
  createReport: (starter: string) => Promise<void>;
  starter: string;
  explanation: string;
  type: string;
}) => {
  const { hovered, ref: hoveredRef } = useHover();

  return (
    <Card
      ref={hoveredRef}
      flex={1}
      onClick={() => {
        void createReport(`${starter}.\n\n${explanation}`);
      }}
      className={classes.item}
      withBorder
      p={"sm"}
      {...props}
    >
      <>
        <Stack gap={"xs"} flex={1}>
          <Badge
            variant="light"
            size="xs"
            color={
              !hovered
                ? "var(--mantine-color-gray-5)"
                : "var(--mantine-primary-color-6)"
            }
          >
            {type}
          </Badge>
          <Text size="sm">{starter}</Text>
        </Stack>
        <Text size="xs" c="dimmed" mt={8}>
          {explanation}
        </Text>
      </>
    </Card>
  );
};
