import React from "react";
import { MarkerType, ReactFlowProvider } from "@xyflow/react";
import { Paper } from "@mantine/core";
import type { LLMVerifyResultsResponseType } from "@mm/shared/companion/types";
import { InnerLayoutFlow } from "./InnerLayoutFlow";

interface QueryDataFlowProps {
  queryDataFlow: LLMVerifyResultsResponseType["queryDataFlow"];
}

export const QueryDataFlow: React.FC<QueryDataFlowProps> = ({
  queryDataFlow: { nodes, edges },
}) => {
  const initialNodes = nodes.map((node) => ({
    id: node.id,
    type: node.type,
    position: { x: 0, y: 0 },
    data: { explanation: node.explanation },
  }));

  const initialEdges = edges.map((edge) => ({
    id: `${edge.source}->${edge.target}`,
    source: edge.source,
    target: edge.target,
    markerEnd: { type: MarkerType.ArrowClosed },
  }));

  return (
    <Paper h={"100%"} w="100%" bg="gray.0" radius={"md"}>
      <ReactFlowProvider>
        <InnerLayoutFlow
          initialNodes={initialNodes}
          initialEdges={initialEdges}
        />
      </ReactFlowProvider>
    </Paper>
  );
};
