import { Table } from "@components/Table";
import { Page } from "@layout/Page";
import React from "react";

import {
  ActionIcon,
  ActionIconGroup,
  Badge,
  Box,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import {
  BiEdit,
  BiExport,
  BiHive,
  BiShow,
  BiTable,
  BiTrash,
} from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useGoldViewsList } from "../api/useGoldViewsList";

type TableItem = Exclude<
  ReturnType<typeof useGoldViewsList>["data"],
  undefined | null
>[number];

export const DataCatalog: React.FC = () => {
  const { data, isPending } = useGoldViewsList();

  const columnHelper = createColumnHelper<TableItem>();

  const columns = [
    columnHelper.display({
      id: "open",
      cell: (props) => (
        <Tooltip label="Open results">
          <ActionIcon
            variant="light"
            component={NavLink}
            to={`/results/${props.row.original.thread_id}`}
            onClick={() => console.log(props.row)}
          >
            <BiTable />
          </ActionIcon>
        </Tooltip>
      ),
    }),
    columnHelper.accessor("table_display_name", {
      cell: (info) => (
        <Box display={"flex"}>
          <Group gap={"xs"}>
            <Tooltip multiline maw={"25rem"} label={info.getValue()}>
              <Text truncate="end">{info.getValue()}</Text>
            </Tooltip>
            <Tooltip label="Rename">
              <ActionIcon variant="subtle" color="gray">
                <BiEdit />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Box>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Name
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      cell: (info) => (
        <Badge
          variant="light"
          color={info.getValue() === "FINAL" ? "green" : "gray"}
        >
          {info.getValue()}
        </Badge>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Status
        </Text>
      ),
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => (
        <Box display={"flex"}>
          <Text truncate="end">
            {dayjs(info.getValue()).utc().format("DD MMM. YYYY [at] HH:mm")}
          </Text>
        </Box>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Created at
        </Text>
      ),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <ActionIconGroup>
          <Tooltip label="Connect">
            <ActionIcon variant="subtle" onClick={() => console.log(props.row)}>
              <BiHive />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Export">
            <ActionIcon variant="subtle" onClick={() => console.log(props.row)}>
              <BiExport />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="View details">
            <ActionIcon variant="subtle" onClick={() => console.log(props.row)}>
              <BiShow />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Remove from catalog">
            <ActionIcon
              variant="subtle"
              color="red"
              onClick={() => console.log(props.row)}
            >
              <BiTrash />
            </ActionIcon>
          </Tooltip>
        </ActionIconGroup>
      ),
    }),
  ];

  if (!columns) return null;

  return (
    <Page title="Data Catalog">
      <Table data={data ?? undefined} isPending={isPending} columns={columns} />
    </Page>
  );
};
