import { Avatar, Card, Group } from "@mantine/core";
import { MessageContent } from "./MessageContent";

export const UserMessage = ({ content }: { content: string }) => (
  <Card radius="md">
    <Group align="flex-start">
      <Avatar color="var(--mantine-primary-color-6)" radius="xl">
        JK
      </Avatar>
      <MessageContent>{content}</MessageContent>
    </Group>
  </Card>
);
