import { Group, Text, TextInput, Badge, Stack, Box } from "@mantine/core";
import type { Concept } from "@mm/shared/companion/types";

export const ConceptItem = ({
  concept,
  updateConcept,
}: {
  concept: Concept;
  updateConcept: (concept: Concept) => void;
}) => {
  const updateDefinition = (definition: string) => {
    updateConcept({
      ...concept,
      definition,
    });
  };

  const existing = concept.existing;

  return (
    <Group>
      <Group style={{ flex: 1 }}>
        <Stack gap={0}>
          <Group>
            <Text>{concept.concept}</Text>
            <Badge size={"sm"} variant="light" color={!existing ? "teal" : ""}>
              {!existing ? "NEW" : "EXISTING"}
            </Badge>
          </Group>
          <Text c={"dimmed"} size="xs">
            {concept.explanation}
          </Text>
        </Stack>
      </Group>
      <Box flex={1}>
        <TextInput
          size="md"
          tabIndex={1}
          placeholder="Enter definition"
          value={concept.definition || ""}
          onChange={(e) => updateDefinition(e.target.value)}
          autoComplete="none"
        />
      </Box>
    </Group>
  );
};
