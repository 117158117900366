import { useClient } from "@hooks/use-client";
import { useQuery } from "@tanstack/react-query";

import type { ReportColumnCandidate } from "@mm/shared/schemas/reports";
import { useParams } from "react-router-dom";
import { groupBySourceAndTable } from "../utils";

export const useJoinableColumns = () => {
  const { fetchAPIWithToken } = useClient();
  const { reportId } = useParams<{ reportId: string }>();

  return useQuery({
    queryKey: ["allJoinableColumns", { reportId }],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/reports/${reportId}/columns/options`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch joinable columns`);
      }

      const { joinableColumns } = await response.json();

      return joinableColumns as ReportColumnCandidate[];
    },
    select: groupBySourceAndTable,
  });
};
