import { Page } from "@layout/Page";
import { TimeOutError } from "@components/Errors";

export const ErrorPage = () => {
  return (
    <Page title="Error">
      <TimeOutError />
    </Page>
  );
};
