import { useClient } from "@hooks/use-client";
import type { Report, ReportChatMessage } from "@mm/shared/schemas/reports";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

interface ReportBuilderContextType {
  report: Report | undefined;
  isPending: boolean;
  postMessage: (message: string) => void;
  isPosting: boolean;
}

const ReportBuilderContext = createContext<
  ReportBuilderContextType | undefined
>(undefined);

interface ReportBuilderProviderProps {
  children: React.ReactNode;
}

export const queryKeyForReport = (reportId: string | number | undefined) => [
  "reports",
  "get",
  String(reportId),
];

export const ReportBuilderProvider: React.FC<ReportBuilderProviderProps> = ({
  children,
}) => {
  const { fetchAPIWithToken } = useClient();
  const queryClient = useQueryClient();
  const { reportId } = useParams<{ reportId: string }>();

  const { data: report, isPending } = useQuery({
    queryKey: queryKeyForReport(reportId),
    queryFn: async () => {
      const response = await fetchAPIWithToken(`/api/reports/${reportId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch columns for report ${reportId}`);
      }

      return (await response.json()) as Report;
    },
    enabled: !!reportId,
  });

  const { mutate: postMessage, isPending: isPosting } = useMutation({
    mutationFn: async (message: string) => {
      const response = await fetchAPIWithToken(
        `/api/reports/${reportId}/chat`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_message: message }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to post message for report ${reportId}`);
      }

      return await response.json();
    },
    onMutate: async (newMessage) => {
      // Cancel any outgoing refetches
      const queryKey = queryKeyForReport(reportId);
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousReport = queryClient.getQueryData<Report>(queryKey);
      //
      // Optimistically update to the new value
      if (previousReport) {
        const now = new Date().toISOString();
        queryClient.setQueryData<Report>(queryKey, {
          ...previousReport,
          messages: [
            ...previousReport.messages,
            // Optimistically add user and waiting messages matching the API response structure
            // This specific structure is required for the messages to pass the filter in Messages.tsx:
            {
              role: "user",
              id: -2, // temporary id for user message
              created_at: now,
              report_id: Number(reportId),
              message: {
                role: "user",
                content: [
                  {
                    text: newMessage,
                    type: "text",
                  },
                ],
              },
            },
            {
              role: "waiting",
              id: -1, // temporary id for waiting message
              created_at: new Date().toISOString(),
              report_id: Number(reportId),
              message: {
                role: "waiting",
                content: [
                  {
                    text: "",
                    type: "text",
                  },
                ],
              } as unknown as ReportChatMessage["message"],
            },
          ],
        });
      }

      // Return a context object with the snapshotted value
      return { previousReport };
    },
    onError: (_err, _newMessage, context) => {
      if (context?.previousReport) {
        queryClient.setQueryData(
          queryKeyForReport(reportId),
          context.previousReport,
        );
      }
    },
    onSettled: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: queryKeyForReport(reportId),
        }),
      ]);
    },
  });

  const value: ReportBuilderContextType = {
    report,
    isPending,
    postMessage,
    isPosting,
  };

  return (
    <ReportBuilderContext.Provider value={value}>
      {children}
    </ReportBuilderContext.Provider>
  );
};

export const useReportBuilder = (): ReportBuilderContextType => {
  const context = useContext(ReportBuilderContext);
  if (context === undefined) {
    throw new Error(
      "useReportBuilder must be used within a ReportBuilderProvider",
    );
  }
  return context;
};
