import { ActionIcon, Card, Divider, Group, Stack, Text } from "@mantine/core";
import { LLMResponseWithReportSuggestions } from "@mm/shared/companion/types";
import { BiAddToQueue } from "react-icons/bi";
import { useReportBuilder } from "../ReportBuilderContext";
import { MessageContent } from "./MessageContent";

export const AssistantMessage = ({ content }: { content: string }) => {
  const { postMessage, isPosting } = useReportBuilder();

  const { text: message, suggestions } = LLMResponseWithReportSuggestions.parse(
    JSON.parse(content),
  );

  return (
    <Card radius="md">
      <Stack gap="sm">
        <MessageContent>{message}</MessageContent>

        {suggestions?.length > 0 && (
          <>
            <Divider label="Suggestions" mx="xl" />
            <Stack>
              {suggestions.map((suggestion) => (
                <Group key={suggestion.name} gap="xs">
                  <ActionIcon
                    disabled={isPosting}
                    variant="subtle"
                    onClick={() =>
                      postMessage(`Add "${suggestion.name}" column`)
                    }
                  >
                    <BiAddToQueue />
                  </ActionIcon>
                  <Stack flex={1} gap={2}>
                    <Text size="sm">{suggestion.name}</Text>
                    <Text size="sm" c="dimmed">
                      {suggestion.explanation}
                    </Text>
                  </Stack>
                </Group>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  );
};
