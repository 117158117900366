import type { FrontendMessage, InterfaceStatus } from "@components/Thread/Chat";
import { Button, Card, Image, rem, Stack, Text, Title } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";

import { Scrollbar } from "@components/Scrollbar";
import { useClient } from "@hooks/use-client";
import DoneImage from "@images/done-suggestions.svg";
import EmptyImage from "@images/empty-suggestions.svg";
import type { OutputMessage } from "@mm/shared/companion/SocketMessage";
import {
  type ChatOptions,
  ChatOptionsParser,
} from "@mm/shared/companion/types";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const Options = ({
  isDone,
  interfaceStatus,
  messages,
  sendMessages,
}: {
  isDone: boolean;
  interfaceStatus: InterfaceStatus;
  messages: OutputMessage[];
  sendMessages: (messages: FrontendMessage[]) => void;
}) => {
  const [options, setOptions] = useState<ChatOptions>([]);
  const { fetchAPIWithToken } = useClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async (text: string) => {
      const response = await fetchAPIWithToken(`/api/post-process`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error("Failed to process message");
      }

      return ChatOptionsParser.parse(await response.json());
    },
    onError: (error) => {
      console.error("Error processing message:", error);
    },
  });

  useEffect(() => {
    if (interfaceStatus === "waiting" && messages.length > 0 && !isPending) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage && latestMessage.role === "assistant") {
        try {
          mutate(latestMessage.text, {
            onSuccess: (data) => {
              setOptions(data);
            },
          });
        } catch (error) {
          console.error("Post-processing error:", error);
        }
      }
    } else {
      setOptions([]);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps -- we can't rely on isPending as it would create an query each time the current query is done being run
  }, [interfaceStatus, messages, fetchAPIWithToken, mutate]);

  const hasActiveSuggestions = options.length > 0;

  return (
    <Stack
      style={{ flexBasis: rem(300), flexShrink: 0, flexGrow: 0 }}
      h={"100%"}
    >
      <Title order={4}>AI suggestions</Title>
      <AnimatePresence mode="wait">
        {isDone ? (
          <motion.div
            key="empty"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            <Stack style={{ textAlign: "center" }} gap={"md"}>
              <Image m={"auto"} w={"30%"} src={DoneImage} />
              <Text size={"sm"} c={"dimmed"}>
                You're done with this step,
                <br />
                click next.
              </Text>
            </Stack>
          </motion.div>
        ) : !hasActiveSuggestions ? (
          <motion.div
            key="empty"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            <Stack style={{ textAlign: "center" }} gap={"md"}>
              <Image m={"auto"} w={"30%"} src={EmptyImage} />
              <Text size={"sm"} c={"dimmed"}>
                No suggestions at this point,
                <br />
                keep chatting.
              </Text>
            </Stack>
          </motion.div>
        ) : (
          <Scrollbar
            flex={1}
            offsetScrollbars
            type="auto"
            scrollbarSize={8}
            styles={{ thumb: { opacity: "0.3" } }}
          >
            <Stack pr={8}>
              {options.map((option, index: number) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.3, delay: index * 0.2 }}
                >
                  <Card withBorder>
                    <Text fw={500} size="sm">
                      {option.suggestion}
                    </Text>

                    <Text flex={1} size="sm" c="dimmed">
                      {option.explanation}
                    </Text>

                    <Button
                      variant="light"
                      fullWidth
                      styles={{
                        label: { whiteSpace: "normal", overflow: "visible" },
                      }}
                      mt="md"
                      p={"xs"}
                      h={"100%"}
                      onClick={() =>
                        sendMessages([
                          {
                            type: "text",
                            text: option.label,
                          },
                        ])
                      }
                    >
                      {option.label}
                    </Button>
                  </Card>
                </motion.div>
              ))}
            </Stack>
          </Scrollbar>
        )}
      </AnimatePresence>
    </Stack>
  );
};
