import {
  Card,
  Center,
  Group,
  Loader,
  Table as MantineTable,
  Pagination,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import {
  type ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

export const Table = <T,>({
  data,
  isPending,
  columns,
}: {
  data?: T[];
  isPending: boolean;
  columns: ColumnDef<T, string>[];
}) => {
  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  });

  return (
    <Card flex={1} radius="lg">
      <Stack flex={1} mih={0}>
        <MantineTable.ScrollContainer flex={1} minWidth={0}>
          <MantineTable verticalSpacing={"md"}>
            <TableHeader table={table} />
            <TableBody table={table} />
          </MantineTable>
        </MantineTable.ScrollContainer>
        {
          /* Cannot put inside table, as div cannot be children of table */
          isPending ? (
            <Center flex={1}>
              <Loader />
            </Center>
          ) : (
            <Group justify="space-between">
              <Stack gap={4}>
                <Text c={"dimmed"} size="sm">
                  {table.getRowCount()} rows in total
                </Text>
                <Pagination
                  size={"sm"}
                  total={table.getPageCount()}
                  siblings={2}
                  boundaries={2}
                  value={table.getState().pagination.pageIndex + 1}
                  onChange={(val) => table.setPageIndex(val - 1)}
                />
              </Stack>
              <Stack gap={4}>
                <Text c={"dimmed"} size="sm" ta={"right"}>
                  Rows per page
                </Text>
                <Select
                  size="sm"
                  defaultValue={String(table.initialState.pagination.pageSize)}
                  allowDeselect={false}
                  data={["10", "20", "30", "40", "50"]}
                  value={String(table.getState().pagination.pageSize)}
                  onChange={(val) => table.setPageSize(Number(val))}
                />
              </Stack>
            </Group>
          )
        }
      </Stack>
    </Card>
  );
};
