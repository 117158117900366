import {
  Text,
  Group,
  Table as MantineTable,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import { BiErrorCircle, BiInfoCircle } from "react-icons/bi";
import type { GoldViewDef } from "./utils";

type Props = {
  lastSuccessGoldView: GoldViewDef | undefined;
  goldView: GoldViewDef;
};

export const TableCaption: React.FC<Props> = ({
  lastSuccessGoldView,
  goldView,
}) => {
  return (
    <MantineTable.Caption ta={"left"}>
      <Group px={8} justify="space-between">
        <Text>{lastSuccessGoldView?.table_display_name ?? ""}</Text>
        <Tooltip
          w={300}
          multiline
          label={goldView.error ?? lastSuccessGoldView?.metadata}
        >
          <ActionIcon
            color={goldView.error ? "red" : undefined}
            variant="transparent"
          >
            {goldView.error ? (
              <BiErrorCircle size={20} />
            ) : (
              <BiInfoCircle size={20} />
            )}
          </ActionIcon>
        </Tooltip>
      </Group>
    </MantineTable.Caption>
  );
};
