import { useClient } from "@hooks/use-client";
import { Badge, Button, Card, Group, Image, Text } from "@mantine/core";
import {
  VISUALIZATION_TYPES,
  type VisualizationType,
} from "@mm/shared/companion/visualizationTypes";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { threadQueryKeys } from "..";

export const VisualizationItem = ({
  selected: isSelected,
  type: visualizationType,
  explanation,
  setVisualizationTypes,
}: VisualizationType & {
  setVisualizationTypes: React.Dispatch<
    React.SetStateAction<VisualizationType[]>
  >;
}) => {
  const { supabase } = useClient();
  const { threadId } = useParams();
  const queryClient = useQueryClient();

  const visualizationCategory =
    VISUALIZATION_TYPES.find((item) => item.type === visualizationType)
      ?.category || "Unknown";

  const imageUrl = new URL(
    `../../../images/charts/${visualizationType}.svg`,
    import.meta.url,
  ).href;

  const handlerOnSelect = async () => {
    await supabase.from("visualization_types").upsert(
      {
        type: visualizationType,
        selected: !isSelected,
        thread_id: Number(threadId),
        explanation: explanation,
      },
      { onConflict: "thread_id" },
    );

    // optimistically update the selected state
    setVisualizationTypes((prevVisualizationTypes) => {
      return prevVisualizationTypes.map((item) => {
        return {
          ...item,
          selected: item.type === visualizationType,
        };
      });
    });

    // invalidate the remote cache, this will re-fetch the data
    // but nothing should be changed compare to our local state
    await queryClient.invalidateQueries({
      queryKey: threadQueryKeys(Number(threadId)).visualizations,
    });
  };

  return (
    <Card
      withBorder
      style={{
        cursor: "pointer",
        userSelect: "none",
        borderColor: isSelected ? "var(--mantine-primary-color-6)" : "",
      }}
      shadow={isSelected ? "md" : "unset"}
      tabIndex={1}
      onClick={() => handlerOnSelect()}
    >
      <Card.Section>
        <Image draggable={false} src={imageUrl} height={100} />
      </Card.Section>
      <Card.Section p="md" flex={1}>
        <Group justify="space-between" mb="xs">
          <Text fw={500}>{visualizationType}</Text>
          <Badge>{visualizationCategory}</Badge>
        </Group>

        {explanation && (
          <Text size="sm" c="dimmed">
            {explanation}
          </Text>
        )}
      </Card.Section>
      <Card.Section>
        <Button
          variant={isSelected ? "light" : "transparent"}
          fullWidth
          size="sm"
          mt="md"
        >
          {isSelected ? "Selected" : "Select"}
        </Button>
      </Card.Section>
    </Card>
  );
};
