import React from "react";
import { Text, Code, Stack, Grid, Box } from "@mantine/core";
import type { LLMVerifyResultsResponseType } from "@mm/shared/companion/types";

interface QueryDetailsProps {
  queryDetails: LLMVerifyResultsResponseType["queryDetails"];
}

const QueryDetails: React.FC<QueryDetailsProps> = ({ queryDetails }) => {
  return (
    <Stack gap={"xs"}>
      {queryDetails.map((chunk, index) => (
        <Box key={index} p="sm">
          <Text fw={"bold"}>{chunk.chunkTitle}</Text>
          <Text mb="sm" c={"dimmed"} size="sm">
            {chunk.chunkExplanation}
          </Text>
          <Grid>
            <Grid.Col span={6}>
              <Text fw={600} size="sm">
                Query section:
              </Text>
              <Code block>
                {chunk.lines.map((line, lineIndex) => (
                  <Text key={lineIndex} size="sm">
                    {line.sql || "\n"}
                  </Text>
                ))}
              </Code>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text fw={600} size="sm">
                Explanations:
              </Text>
              <Code block>
                {chunk.lines.map((line, lineIndex) => (
                  <Text key={lineIndex} size="sm">
                    {line.explanation || "\n"}
                  </Text>
                ))}
              </Code>
            </Grid.Col>
          </Grid>
        </Box>
      ))}
    </Stack>
  );
};

export default QueryDetails;
