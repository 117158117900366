import { Center, Loader, ScrollArea, Stack } from "@mantine/core";
import React, { useEffect, useRef } from "react";
import { useReportBuilder } from "../ReportBuilderContext";
import { Message } from "./Message";

export const Messages: React.FC = () => {
  const messagesRef = useRef<HTMLDivElement>(null);
  const { report, isPending } = useReportBuilder();

  const scrollToBottom = () =>
    messagesRef.current!.scrollTo({
      top: messagesRef.current!.scrollHeight,
      behavior: "smooth",
    });

  useEffect(
    () => scrollToBottom(),
    [messagesRef.current?.clientHeight, report?.messages],
  );

  return (
    <ScrollArea.Autosize
      scrollbars="y"
      viewportRef={messagesRef}
      offsetScrollbars
    >
      {!report || isPending ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Stack gap={"md"} pr={8}>
          {report.messages.flatMap((message) => {
            // Content can be a string, array or null/undefined
            const content = message.message?.content;
            let messageContent = "";

            /* content can be null when the assistant is doing a tool call
             * we want to filter those as we are showing the output of the
             * tool call anyway*/
            if (!content) {
              return [];
            }

            if (typeof content === "string") {
              messageContent = content;
            } else if (content && content[0] && "text" in content[0]) {
              messageContent = content[0]["text"];
            } else {
              console.warn(`Unknown message content type: ${content}`);
              return [];
            }

            return [
              <Message
                key={message.id}
                role={message.role}
                content={messageContent}
              />,
            ];
          })}
        </Stack>
      )}
    </ScrollArea.Autosize>
  );
};
