import { useClient } from "@hooks/use-client";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

export const useThreadsList = () => {
  const { supabase, user } = useClient();
  const getUserId = useCallback(() => user.data!.id, [user.data]);

  return useQuery({
    queryKey: ["openedThreadsList"],
    queryFn: async () => {
      const { data } = await supabase
        .from("threads")
        .select(
          `id,
          name,
          created_at,
          gold_views (id)`,
        )
        .eq("user_id", getUserId())
        .is("gold_views", null)
        .order("created_at", { ascending: false });

      return data;
    },
  });
};
