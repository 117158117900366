import { useClient } from "@hooks/use-client";
import {
  Badge,
  Box,
  Card,
  Center,
  Group,
  Loader,
  Table as MantineTable,
  Pagination,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  formatDataValue,
  isFormattedData,
  type FormattedData,
} from "@mm/shared/data/FormattedData";
import { useQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type Row,
  type SortingState,
} from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { z } from "zod";
import { useResultsColumns } from "./ResultsContext";
import { TableBody } from "./TableBody";
import { TableCaption } from "./TableCaption";
import { TableHeader } from "./TableHeader";
import type { GoldViewDef } from "./utils";

const TableResultDataParser = z.object({ data: z.record(z.unknown()).array() });

/*
 * Compare the values from `rowA` and `rowB` for column `columnId`
 */
export const sortingFn = (
  rowA: Row<Record<string, FormattedData>>,
  rowB: Row<Record<string, FormattedData>>,
  columnId: string,
) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);

  const sortValueA = isFormattedData(a) ? a.sortValue : null;
  const sortValueB = isFormattedData(b) ? b.sortValue : null;

  // Handle null values
  if (sortValueA === null && sortValueB === null) return 0;
  if (sortValueA === null) return -1;
  if (sortValueB === null) return 1;

  // Handle dates
  if (sortValueA instanceof Date && sortValueB instanceof Date) {
    return sortValueA.getTime() - sortValueB.getTime();
  }

  // Handle numbers
  if (typeof sortValueA === "number" && typeof sortValueB === "number") {
    return sortValueA - sortValueB;
  }

  // Default string comparison
  return String(sortValueA).localeCompare(String(sortValueB));
};

// Display component that just renders the pre-formatted display value
const CellContent: React.FC<{
  formattedValue: FormattedData;
}> = ({ formattedValue: { display } }) => {
  if (display === "empty") {
    return (
      <Text c="dimmed" size="sm" truncate="end">
        empty
      </Text>
    );
  }

  return (
    <Box display="flex">
      <Tooltip multiline maw="25rem" label={display}>
        <Text truncate="end">{display}</Text>
      </Tooltip>
    </Box>
  );
};

const headerContent =
  ({ name, isNew }: { name: string; isNew: boolean }) =>
  () => {
    return (
      <Group gap="xs" style={{ flexWrap: "nowrap" }}>
        <Tooltip multiline maw="25rem" label={name}>
          <Text fw="bold" truncate="end">
            {name}
          </Text>
        </Tooltip>
        {isNew && (
          <Tooltip label="New data column created by your companion">
            <Box flex={0}>
              <Badge
                size="sm"
                display="flex"
                variant="dot"
                color="green"
                c="dimmed"
              >
                new
              </Badge>
            </Box>
          </Tooltip>
        )}
      </Group>
    );
  };

const useResultsData = (goldViewId: number, limit: number = 1_000_000) => {
  const { fetchAPIWithToken } = useClient();

  return useQuery({
    enabled: !!goldViewId,
    queryKey: ["resultsData", { goldViewId }, { limit }],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/gold/views/${goldViewId}/data?limit=${limit}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data for gold view ${goldViewId}`);
      }

      const { data } = TableResultDataParser.parse(await response.json());
      return data;
    },
  });
};

export const Table = ({
  lastSuccessGoldView,
  goldView,
}: {
  lastSuccessGoldView: GoldViewDef | undefined;
  goldView: GoldViewDef;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data: rawColumnData } = useResultsColumns(goldView);
  const { data: rawData, isPending } = useResultsData(goldView.id, 1000);

  // Process the data once, memoizing both display and sort values
  const processedData = useMemo(() => {
    if (!rawData) return [];

    return rawData.map((row) => {
      const processedRow: Record<string, FormattedData> = {};

      for (const [key, value] of Object.entries(row)) {
        processedRow[key] = formatDataValue(value);
      }

      return processedRow;
    });
  }, [rawData]);

  const columnHelper = createColumnHelper<(typeof processedData)[0]>();

  const columns = useMemo(() => {
    if (rawColumnData && rawColumnData.length > 0) {
      return rawColumnData.map(({ name, isNew }) =>
        columnHelper.accessor(name, {
          cell: (info) => <CellContent formattedValue={info.getValue()} />,
          header: headerContent({ name, isNew }),
          sortingFn: sortingFn,
        }),
      );
    }
    return [];
  }, [rawColumnData, columnHelper]);

  const table = useReactTable({
    data: processedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: sorting,
    },
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  });

  if (isPending) {
    return (
      <Card flex={1} radius="lg">
        <Stack flex={1} style={{ overflow: "hidden" }}>
          <Center flex={1}>
            <Loader />
          </Center>
        </Stack>
      </Card>
    );
  }

  return (
    <Card flex={1} radius="lg">
      <Stack flex={1} style={{ overflow: "hidden" }}>
        <MantineTable.ScrollContainer flex={1} minWidth={0}>
          <MantineTable
            stickyHeader
            striped
            highlightOnHover
            captionSide="top"
            layout="fixed"
            verticalSpacing="md"
          >
            <TableCaption
              lastSuccessGoldView={lastSuccessGoldView}
              goldView={goldView}
            />
            <TableHeader table={table} />
            <TableBody table={table} />
          </MantineTable>
        </MantineTable.ScrollContainer>
        <Group justify="space-between">
          <Stack gap={4}>
            <Text c="dimmed" size="sm">
              {table.getRowCount()} rows in total
            </Text>
            <Pagination
              size="sm"
              total={table.getPageCount()}
              siblings={2}
              boundaries={2}
              value={table.getState().pagination.pageIndex + 1}
              onChange={(val) => table.setPageIndex(val - 1)}
            />
          </Stack>
          <Stack gap={4}>
            <Text c="dimmed" size="sm" ta="right">
              Rows per page
            </Text>
            <Select
              size="sm"
              defaultValue={String(table.initialState.pagination.pageSize)}
              allowDeselect={false}
              data={["10", "20", "30", "40", "50"]}
              value={String(table.getState().pagination.pageSize)}
              onChange={(val) => table.setPageSize(Number(val))}
            />
          </Stack>
        </Group>
      </Stack>
    </Card>
  );
};
