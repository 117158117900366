import { Card } from "@mantine/core";
import { MessageContent } from "./MessageContent";

export const ToolMessage = ({ content }: { content: string }) => (
  /* Card component so we have consistent x-padding/x-margin with the other messages in the chat */
  <Card radius="md" bg={"transparent"} py={0}>
    <MessageContent c={"dimmed"} fz={"xs"}>
      {content}
    </MessageContent>
  </Card>
);
