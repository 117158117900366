import { useClient } from "@hooks/use-client";
import type { SocketMessage } from "@mm/shared/companion/SocketMessage";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useChat = ({
  threadId,
  onMessage,
}: {
  threadId: number;
  onMessage: (data: SocketMessage) => void;
}) => {
  const { fetchAPIWithToken } = useClient();
  const [error, setError] = useState<string | null>(null);

  const processSSEResponse = useCallback(
    (response: Response) => {
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Unable to read stream");
      }

      /*
       * The current implementation of `processSSEResponse` is quite complex.
       * we need to be careful because Server-Sent Events (SSE) are typically streamed, and we want to process them as they arrive
       * rather than waiting for the entire response to complete.
       */
      return new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const text = new TextDecoder().decode(value);
            const lines = text.split("\n\n");
            for (const line of lines) {
              if (line.startsWith("data:")) {
                const jsonStr = line.slice(5).trim();
                try {
                  const data = JSON.parse(jsonStr) as SocketMessage;
                  onMessage(data);
                } catch (e) {
                  console.error("Error parsing SSE message:", e);
                }
              }
            }
            controller.enqueue(value);
          }
          controller.close();
          reader.releaseLock();
        },
      });
    },
    [onMessage],
  );

  // Query for initial loading of messages
  const { isLoading: isLoadingInitialMessages, refetch: refetchMessages } =
    useQuery({
      queryKey: ["initialMessages", threadId, processSSEResponse],
      queryFn: () =>
        fetchAPIWithToken(`/api/kpi/${threadId}/stream`).then(
          processSSEResponse,
        ),
      enabled: false, // We'll manually trigger this query
      retry: false,
    });

  const sendMessage = useMutation({
    mutationFn: async (message: SocketMessage) => {
      const response = await fetchAPIWithToken(`/api/kpi/${threadId}/stream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return processSSEResponse(response);
    },
    onError: (error: Error) => {
      console.error("Error sending message:", error);
      setError(error.message);
    },
  });

  const loadMessagesFromThread = useCallback(() => {
    return refetchMessages();
  }, [refetchMessages]);

  // Effect to load initial messages when threadId changes
  useEffect(() => {
    if (threadId) {
      loadMessagesFromThread().catch((e) => console.error(e));
    }
  }, [threadId, loadMessagesFromThread]);

  return {
    sendSocketMessage: sendMessage.mutate,
    error,
    isPending: sendMessage.isPending || isLoadingInitialMessages,
  };
};
