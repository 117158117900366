import { Auth as AuthLayout } from "@layout/Auth";
import { Main as MainLayout } from "@layout/Main";

import { DataCatalog } from "@pages/DataCatalog";
import { ErrorPage } from "@pages/ErrorPage";
import { Home } from "@pages/Home";
import { Login } from "@pages/Login";
import { OpenProjects } from "@pages/OpenProjects";
import { ReportBuilder } from "@pages/ReportBuilder";
import { Results } from "@pages/Results";
import { Settings } from "@pages/Settings";
import { Thread } from "@pages/Thread";

import { STEPS } from "@components/Steps";
import { useClient } from "@hooks/use-client";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createBrowserRouter,
  useLocation,
  useRouteError,
} from "react-router-dom";
import { GoldViewResults } from "@components/ReportBuilder/Results/GoldViewResults";

const ThreadWrapper = () => {
  const location = useLocation();
  const currentStep = STEPS.findIndex((step) =>
    location.pathname.endsWith(step.url),
  );
  const index = currentStep === -1 ? 0 : currentStep;
  return <Thread key={index} stepIndex={index} />;
};

const SentryRouteErrorFallback = () => {
  const { supabase } = useClient();
  const error = useRouteError() as Error;

  useEffect(() => {
    const sendError = async () => {
      const session = await supabase.auth.getSession();

      Sentry.withScope((scope) => {
        scope.setUser({
          id: session.data.session?.user.id,
          email: session.data.session?.user.email,
        });

        Sentry.captureException(error);
      });
    };

    sendError().catch((e) =>
      console.warn(`unable to send data to sentry, ${e}`),
    );
  }, [error, supabase]);

  return null;
};

export const router: ReturnType<typeof createBrowserRouter> =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
    {
      path: "/login",
      element: <AuthLayout />,
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "/login/pin",
          element: <Login usePINCode={true} />,
        },
      ],
    },
    {
      element: <MainLayout />,
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/thread/:threadId",
          element: <ThreadWrapper />,
          children: STEPS.map(({ url }) => ({
            path: url,
            element: <ThreadWrapper />,
          })),
        },
        {
          path: "/report/:reportId",
          element: <ReportBuilder />,
        },
        {
          path: "/views/:goldViewId",
          element: <GoldViewResults />,
        },
        {
          path: "/data-catalog",
          element: <DataCatalog />,
        },
        {
          path: "/open-projects",
          element: <OpenProjects />,
        },
        {
          path: "/results/:threadId",
          element: <Results />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/error",
          element: <ErrorPage />,
        },
      ],
    },
  ]);
