import { useClient } from "@hooks/use-client";
import type { Thread } from "@mm/shared/companion/types";
import { useQuery } from "@tanstack/react-query";

export const useThread = (threadId: number) => {
  const { fetchAPIWithToken } = useClient();

  return useQuery({
    queryKey: ["threads", { threadId }],
    queryFn: async () => {
      const response = await fetchAPIWithToken(`/api/threads/${threadId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Unable to retrieve thread with id: ${threadId}`);
      }

      return (await response.json()) as Thread;
    },
  });
};
