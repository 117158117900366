import {
  ReportBuilderProvider,
  useReportBuilder,
} from "@components/ReportBuilder/ReportBuilderContext";
import { Table } from "@components/ReportBuilder/Table";
import { useClient } from "@hooks/use-client";
import { Page } from "@layout/Page";
import { Button, Group, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import type { Report } from "@mm/shared/schemas/reports";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const ReportBuilder: React.FC = () => {
  return (
    <ReportBuilderProvider>
      <ReportBuilderContentWrapper />
    </ReportBuilderProvider>
  );
};

const goldViewResultParser = z.object({
  reportId: z.number(),
  goldViewId: z.number(),
  sql: z.string(),
});

const useFinalizeView = (reportId: number) => {
  const navigate = useNavigate();
  const { fetchAPIWithToken } = useClient();

  return useMutation({
    mutationFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/reports/${reportId}/generate`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Unable to generate the data for this specific report`);
      }

      return goldViewResultParser.parse(await response.json());
    },
    onSuccess: async (data) => {
      navigate(`/views/${data.goldViewId}`);
    },
    onError: () => {
      notifications.show({
        title: "Unable to create view",
        message: "We were not able to create the final view for this report.",
        color: "red",
      });
    },
  });
};

const ReportBuilderContent: React.FC<{ report: Report }> = ({ report }) => {
  const { isPending } = useReportBuilder();
  const { mutate: finalize, isPending: isCreatingReport } = useFinalizeView(
    report.report.id,
  );

  return (
    <Page
      fullWidth
      title={isPending ? "" : report.report.name}
      subtitle={"Report Builder"}
      rightSection={
        <Group gap={"xs"} ta={"right"}>
          {isPending ? (
            <Text c={"dimmed"} size="xs">
              Loading &hellip;
            </Text>
          ) : (
            <Text c={"dimmed"} size="xs">
              {report.columns.length} column
              {report.columns.length > 1 ? `s` : ``}
            </Text>
          )}
          <Button
            size="xs"
            disabled={report.columns.length == 0}
            loading={isCreatingReport}
            leftSection={<BiCheckCircle size={14} />}
            onClick={() => finalize()}
          >
            View results
          </Button>
        </Group>
      }
    >
      <Table />
    </Page>
  );
};

const ReportBuilderContentWrapper: React.FC = () => {
  const { report } = useReportBuilder();

  if (!report) {
    return null;
  }

  return <ReportBuilderContent report={report} />;
};
