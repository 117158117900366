import { useClient } from "@hooks/use-client";
import { useQuery } from "@tanstack/react-query";

export const useGoldViewsList = () => {
  const { supabase } = useClient();

  return useQuery({
    queryKey: ["goldViewsList"],
    queryFn: async () => {
      const { data } = await supabase.rpc(
        "list_latest_gold_views",
        {},
        {
          get: true,
        },
      );

      return data;
    },
  });
};
