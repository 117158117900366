import { Modal } from "@mantine/core";

import { JsonViewer } from "@textea/json-viewer";
import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";

export const JSONModal = ({
  status,
  opened,
  close,
}: {
  status?: ProcessStatus | undefined;
  opened: boolean;
  close: () => void;
}) => {
  return (
    <Modal opened={opened} onClose={close} size="xl" title="JSON Preview">
      <JsonViewer value={status} defaultInspectDepth={2} />
    </Modal>
  );
};
