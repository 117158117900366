import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useClient } from "@hooks/use-client";

import { Center, useMantineTheme } from "@mantine/core";

import * as Sentry from "@sentry/react";
import { Menu } from "@components/Menu";

export const Main = () => {
  const { hash } = useLocation();
  const theme = useMantineTheme();
  const { user } = useClient();

  const isMenuOpen = hash === "#menu";

  if (user.loading) {
    return null;
  }
  if (!user.data) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <Center flex={1} bg={theme.colors?.gray?.[0]}>
      <Sentry.ErrorBoundary>
        {isMenuOpen && <Menu />}
        <Outlet />
      </Sentry.ErrorBoundary>
    </Center>
  );
};
