import { AssistantMessage } from "./AssistantMessage";
import { ToolMessage } from "./ToolMessage";
import { UserMessage } from "./UserMessage";
import { WaitingMessage } from "./WaitingMessage";

const messageComponents = {
  waiting: WaitingMessage,
  assistant: AssistantMessage,
  user: UserMessage,
  tool: ToolMessage,
} as const;

const isSupportedMessage = (
  message: string,
): message is keyof typeof messageComponents => {
  return Object.keys(messageComponents).includes(message);
};

type MessageProps = {
  role: string;
  content: string;
};

export const Message = ({ role, content }: MessageProps) => {
  /* let the calling component call us with any role, and we will deal with errors
   * in this "dispatch" component instead */
  if (!isSupportedMessage(role)) {
    console.warn(`Unknown message role: ${role}`);
    return null;
  }

  const MessageComponent = messageComponents[role];
  return <MessageComponent content={content} />;
};
