import { useClient } from "@hooks/use-client";

import {
  ActionIcon,
  Affix,
  Box,
  Loader,
  rem,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";

import { Form } from "@components/Home/Form";
import { Recommendations } from "@components/Home/Recommendations";
import { useMutation } from "@tanstack/react-query";
import { BiArrowToRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const useStartReport = () => {
  const { fetchAPIWithToken } = useClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (starter: string) => {
      const response = await fetchAPIWithToken(`/api/reports/start`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ starter }),
      });

      if (!response.ok) {
        throw new Error(`Failed to start report`);
      }

      return (await response.json()) as { id: number };
    },
    onSuccess: (data) => {
      navigate(`/report/${data.id}`);
    },
  });
};

export const Home = () => {
  const navigate = useNavigate();
  const { mutate, isPending } = useStartReport();

  const createReport = async (starter: string) => {
    try {
      mutate(starter);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Affix position={{ top: rem(30), left: rem(30) }}>
        <Tooltip label="Show previous threads" position="right" offset={8}>
          <ActionIcon
            color="gray"
            variant={"subtle"}
            size={"md"}
            onClick={() => navigate({ hash: "menu" })}
          >
            <BiArrowToRight style={{ width: rem(18) }} />
          </ActionIcon>
        </Tooltip>
      </Affix>
      <Stack
        w={750}
        py={"xl"}
        h={"100vh"}
        align="center"
        justify="space-between"
      >
        <Stack gap={"xs"}>
          <Text size="xl">Welcome</Text>
          <Title order={2}>Define your KPI, report or explore your data</Title>
        </Stack>
        {isPending ? (
          <>
            <Stack align="center">
              <Loader size={"xl"} type="dots" />
              <Text c={"dimmed"}>
                Analyzing your needs, and crunching your data &hellip;
              </Text>
            </Stack>
            {/* Empty box so the loader is centered and not pushed at the bottom due to justify="space-between" */}
            <Box />
          </>
        ) : (
          <>
            <Recommendations createReport={createReport} />
            <Form w={550} createReport={createReport} />
          </>
        )}
      </Stack>
    </>
  );
};
