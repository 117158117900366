import { z } from "zod";

export const ProcessStatusSchema = z.object({
  key_metric: z.object({ key: z.string().nullable() }).optional(),
  timeframe: z.object({ key: z.string().nullable() }).optional(),
  filters: z.object({ key: z.string().array().nullable() }).optional(),
  filter_values: z.object({ key: z.string().array().nullable() }).optional(),
  groupings: z.object({ key: z.string().array().nullable() }).optional(),
  aggregation_methods: z
    .object({ key: z.string().array().nullable() })
    .optional(),
  orderings: z.object({ key: z.string().array().nullable() }).optional(),
  kpi_name: z.object({ key: z.string().nullable() }).optional(),
});

export type ProcessStatus = z.output<typeof ProcessStatusSchema>;
