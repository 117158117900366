import { Page } from "@layout/Page";
import { Text, Card, Center, Stack, Button } from "@mantine/core";
import { BiLogoGmail } from "react-icons/bi";
import type { GoldViewDef } from "../utils";
import ReactMarkdown from "react-markdown";
import { QueryHandlingError } from "@mm/shared/schemas/text2sql/errors";

export const ShowQueryHandlerError = ({
  goldView,
}: {
  goldView: GoldViewDef;
}) => {
  if (goldView.status != `ERROR.${QueryHandlingError.NAME}`) {
    console.error(
      `We should not try to render this component if the error is not ERROR.${QueryHandlingError.NAME}`,
    );
    return undefined;
  }

  return (
    <Page title="Insight Results">
      <Card flex={1} radius="lg">
        <Center flex={1}>
          <Stack>
            <Text>
              We apologize for the inconvenience, but our systems were not able
              to produce a solution for you query:
            </Text>
            <ReactMarkdown>{goldView.error}</ReactMarkdown>
            <Center>
              <Button
                href="mailto:guillaume@magemetrics.com"
                leftSection={<BiLogoGmail size={14} />}
                component="a"
                variant="default"
              >
                Contact us to get support
              </Button>
            </Center>
          </Stack>
        </Center>
      </Card>
    </Page>
  );
};
