export interface RunError extends Error {
  details?: Record<string, unknown>;
}

export class SQLGenerationTimeout implements RunError {
  static NAME: string = "SQL_GENERATION_TIMEOUT";

  name: string = SQLGenerationTimeout.NAME;
  message: string;
  details?: Record<string, unknown>;

  constructor(message: string, details?: Record<string, unknown>) {
    this.name = SQLGenerationTimeout.NAME;
    this.message = message;
    this.details = details;
  }
}

export class UnexpectedError implements RunError {
  static NAME: string = "UNEXPECTED_ERROR";

  name: string = UnexpectedError.NAME;
  message: string;
  details?: Record<string, unknown>;

  constructor(message: string, details?: Record<string, unknown>) {
    this.message = message;
    this.details = details;
  }
}

export class QueryHandlingError implements RunError {
  static NAME: string = "QUERY_HANDLING_ERROR";

  name: string = QueryHandlingError.NAME;
  message: string;
  details?: Record<string, unknown>;

  constructor(message: string, details?: Record<string, unknown>) {
    this.message = message;
    this.details = details;
  }
}
