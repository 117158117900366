import { type Dispatch, type SetStateAction, useState } from "react";

import { useChat } from "@hooks/use-chat";
import { FaExclamationCircle, FaHome, FaSync } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Button, Group, Paper, Stack, Text } from "@mantine/core";

import { Form } from "@components/Thread/Form";
import { MessagesList } from "@components/Thread/MessagesList";

import { Options } from "@components/Thread/Options";

import type { ProcessStatus } from "@mm/shared/companion/schemas/ProcessStatus";
import type {
  OutputMessage,
  SocketMessage,
} from "@mm/shared/companion/SocketMessage";
import { useCallback } from "react";

/*
 * This type represent a "temporary message" sent by the user from the frontend
 * it's there in order to optimisticaly show the conversation to the user
 * without waiting on OpenAI's response.
 * This will probably have to be updated as we add more conversational modules
 * Those messages get converted into a EmulatedFrontendMessage that has a structure
 * closer to OpenAI expectation in order to simplify the rendering logic of the frontend
 * */
export type FrontendMessage = { type: "text"; text: string };

const ShowErrorActions = () => {
  const navigate = useNavigate();

  return (
    <Paper
      shadow="md"
      p="xl"
      style={{
        maxWidth: "500px",
        margin: "2rem auto",
        textAlign: "center",
      }}
    >
      <Stack align="center" gap="md">
        <Group gap="sm">
          <FaExclamationCircle size={24} color="var(--mantine-color-red-6)" />
          <Text size="xl" fw={700} c="red">
            Something wrong happened
          </Text>
        </Group>
        <Text size="sm" c="dimmed">
          We apologize for the inconvenience. Please try reloading the page
        </Text>
        <Group>
          <Button
            leftSection={<FaSync size={14} />}
            onClick={() => {
              window.location.reload();
            }}
            variant="filled"
            color="blue"
          >
            Reload Page
          </Button>
          <Button
            leftSection={<FaHome size={14} />}
            onClick={() => {
              navigate("/");
            }}
            variant="filled"
            color="blue"
          >
            Return to homepage
          </Button>
        </Group>
      </Stack>
    </Paper>
  );
};

/**
 * This method is adding a message received from the websocket
 * into the current view's state
 * @param messages the current messages in the thread
 * @param update the new message to append to the thread
 * @returns the list of messages in the thread
 */
const newMessageInThread = (
  messages: OutputMessage[],
  update: string,
): OutputMessage[] => {
  const lastMessage = messages[messages.length - 1];

  if (lastMessage && lastMessage.role === "assistant") {
    // If the last message is from "assistant" and its last content element is of type "text", append the item to it

    return [
      ...messages.slice(0, -1),
      {
        ...lastMessage,
        text: lastMessage.text + update,
      },
    ];
  } else {
    // Otherwise, create a new "assistant" message with the new text content
    return [
      ...messages,
      {
        role: "assistant",
        type: "text",
        text: update,
      },
    ];
  }
};
export type InterfaceStatus = "error" | "running" | "waiting";
export const Chat = ({
  threadId,
  setStatus,
  status,
  nextStep,
}: {
  threadId: number;
  status: ProcessStatus;
  setStatus: Dispatch<SetStateAction<ProcessStatus>>;
  nextStep: () => void;
}) => {
  const [messages, setMessages] = useState<OutputMessage[]>([]);
  const [interfaceStatus, setInterfaceStatus] =
    useState<InterfaceStatus>("waiting");

  // we are done if the kpi_name has been generated
  const isDone =
    (status?.kpi_name?.key !== undefined && interfaceStatus === "waiting") ||
    false;

  const handler = useCallback(
    (data: SocketMessage) => {
      switch (data.action) {
        case "loadMessagesFromThreadResponse": {
          setMessages(data.messages);
          break;
        }

        case "newMessageInThread": {
          setMessages((prevMessages) => {
            return newMessageInThread(prevMessages, data.item);
          });

          break;
        }

        case "error": {
          console.error(data.error);
          setInterfaceStatus("error");
          break;
        }

        case "threadStatusUpdate": {
          setInterfaceStatus(data.status);
          break;
        }
        case "statusUpdate": {
          setStatus(data.status);
          break;
        }
        default:
          break;
      }
    },
    [setStatus],
  );

  const { sendSocketMessage } = useChat({ threadId, onMessage: handler });

  const sendMessages = useCallback(
    (messages: FrontendMessage[]) => {
      const convertedMessage: OutputMessage[] = messages.map((element) => {
        return {
          role: "user",
          text: element.text,
          type: "text",
        };
      });

      setMessages((messages) => [...messages, ...convertedMessage]);

      if (threadId) {
        sendSocketMessage({
          action: "addMessageToThread",
          threadId: threadId,
          content: messages,
        });
      }
    },
    [sendSocketMessage, threadId],
  );

  return (
    <>
      <Options
        messages={messages}
        interfaceStatus={interfaceStatus}
        sendMessages={sendMessages}
        isDone={isDone}
      />
      <Stack flex={1} h={"100%"} justify={"flex-end"}>
        <MessagesList messages={messages} />
        {(() => {
          if (interfaceStatus === "error") {
            return <ShowErrorActions />;
          }

          if (messages.length === 0) {
            return null;
          }

          if (isDone) {
            return <Button onClick={nextStep}>Next</Button>;
          }
          return (
            <Form
              sendMessages={sendMessages}
              loading={interfaceStatus == "running"}
            />
          );
        })()}
      </Stack>
    </>
  );
};
