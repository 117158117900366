import { LoadingOverlay, Stack } from "@mantine/core";
import { useCallback, useEffect } from "react";

import { AssistantMessage, UserMessage } from "@components/Thread/Messages";

import { Scrollbar } from "@components/Scrollbar";
import { useShadowScroll } from "@hooks/use-shadow-scroll";
import type { OutputMessage } from "@mm/shared/companion/SocketMessage";
import classes from "./MessagesList.module.css";

const RenderMessage = ({
  message,
  index,
}: {
  message: OutputMessage;
  index: number;
}) => {
  switch (message.type) {
    case "text": {
      if (message.role === "assistant") {
        return (
          <AssistantMessage key={`${index}_${index}`}>
            {message.text}
          </AssistantMessage>
        );
      } else if (message.role === "user") {
        return (
          <UserMessage key={`${index}_${index}`}>{message.text}</UserMessage>
        );
      }
      break;
    }
    default: {
      return "NOT_HANDLED";
    }
  }
};

export const MessagesList = ({ messages }: { messages: OutputMessage[] }) => {
  const { fullyScrolled, hasScroll, viewportRef, onScrollPositionChange } =
    useShadowScroll();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = useCallback(
    () =>
      viewportRef.current!.scrollTo({
        top: viewportRef.current!.scrollHeight,
        behavior: "smooth",
      }),
    [viewportRef],
  );

  return (
    <Scrollbar
      className={`${hasScroll ? classes.scrollarea : ""} ${
        fullyScrolled ? classes.scrolledToBottom : ""
      }`}
      viewportRef={viewportRef}
      flex={1}
      offsetScrollbars
      type="hover"
      scrollbarSize={8}
      onScrollPositionChange={onScrollPositionChange}
    >
      <Stack gap={"xs"} pr={8}>
        <LoadingOverlay
          visible={messages.length === 0}
          zIndex={1000}
          overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        />
        {messages.flatMap((message, index) => (
          <RenderMessage message={message} index={index} key={index} />
        ))}
      </Stack>
    </Scrollbar>
  );
};
