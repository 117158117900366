import { useEffect, useState } from "react";

import { useClient } from "@hooks/use-client";
import { useNavigate, useParams } from "react-router-dom";

import { Stack, Text } from "@mantine/core";

import { STEPS } from "@components/Steps";
import { Page } from "@layout/Page";
import {
  type ProcessStatus,
  ProcessStatusSchema,
} from "@mm/shared/companion/schemas/ProcessStatus";
import { useQuery } from "@tanstack/react-query";

const useStepper = (initialStep = 0) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const navigate = useNavigate();

  const nextStep = () =>
    setActiveStep((current) => {
      const next = current < STEPS.length - 1 ? current + 1 : current;
      const nextStep = STEPS[next]?.url;
      if (nextStep) {
        navigate(nextStep);
      }
      return next;
    });

  const previousStep = () =>
    setActiveStep((current) => {
      const prev = current > 0 ? current - 1 : current;
      const prevStep = STEPS[prev]?.url;
      if (prevStep) {
        navigate(prevStep);
      }

      return prev;
    });

  return { activeStep, setActiveStep, nextStep, previousStep };
};

const useThreadProcessStatus = (threadId: number) => {
  const { supabase } = useClient();
  const [status, setStatus] = useState<ProcessStatus>({});

  const { data: processStatus } = useQuery({
    queryKey: ["threads", { threadId }, "processStatus"],
    queryFn: async () => {
      const { data } = await supabase
        .from("threads")
        .select("process_status")
        .eq("id", threadId)
        .single();

      if (data?.process_status) {
        const processStatus = ProcessStatusSchema.safeParse(
          data.process_status,
        ).data;
        return processStatus ?? {};
      }

      return {};
    },
  });

  /**
   * This is not recommended, but we don't want to refresh the initial state
   * this will be updated by the websocket on the kpi definition page
   */
  useEffect(() => {
    if (processStatus) {
      setStatus(processStatus);
    }
  }, [processStatus]);

  return [status, setStatus] as const;
};

export const Thread = ({ stepIndex }: { stepIndex: number }) => {
  const { threadId } = useParams<{ threadId: string }>();
  const { activeStep, nextStep, previousStep } = useStepper(stepIndex);
  const [status, setStatus] = useThreadProcessStatus(Number(threadId));

  const currentStep = STEPS[activeStep];

  if (!currentStep) {
    return null;
  }
  const CurrentStepComponent = currentStep.component;

  return (
    <Page
      title="Insight Designer"
      rightSection={
        <Stack gap={0} ta={"right"}>
          <Text fw={"bold"} fz={"sm"}>
            Step {activeStep + 1} / {STEPS.length}
          </Text>
          <Text fz={"sm"} c={"dimmed"}>
            {currentStep.description}
          </Text>
        </Stack>
      }
    >
      <CurrentStepComponent
        key={threadId}
        setStatus={setStatus}
        nextStep={nextStep}
        previousStep={previousStep}
        status={status || {}}
      />
    </Page>
  );
};
